import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProfileViewState } from "../../../utils/interfaces/store/IStore";
import { ProfilexColdex } from "../../../utils/types/ProfilexColdex";
import { IPayloadSetdata, IPayloadSetError } from "../../Interfaces";

const initialState: IProfileViewState = {
  selected: [],
  profiles: [],
  idPolls: [],
  shortMessage: "",
  resultMessage: "",
  dataError: false,
  open: false,
  isLoading: false,
  exported: false,
  securitySaved: false,
};

export const profileViewSlice = createSlice({
  name: "profileView",
  initialState,
  reducers: {
    clean: (state) => {
      return {...initialState}
    },
    select: (
      state,
      { payload }: PayloadAction<ProfilexColdex>
    ) => {
      const index = state.selected.findIndex(({id}) => id === payload.id)
      if (index === -1) {
        state.selected.push(payload)
      } else {
        state.selected.splice(index, 1);
      }
    },
    getUserProfiles: (state) => {
      state.isLoading = true;
    },
    getUserProfilesSuccess: (
      state,
      { payload }: PayloadAction<IPayloadSetdata<ProfilexColdex[]>>
    ) => {
      state.isLoading = false;
      state.userProfiles = payload.resultObject;
      state.selected = payload.resultObject;
      state.exported = true;
    },
    getUserProfilesError: (state, { payload }: PayloadAction<IPayloadSetError>) => {
      state.isLoading = false;
      state.dataError = payload.iserror;
      state.resultMessage = payload.error;
      state.shortMessage = payload.shorterror;
      state.open = payload.open!;
    },
    getProfiles: (state) => {
      state.isLoading = true;
    },
    getProfilesSuccess: (
      state,
      { payload }: PayloadAction<IPayloadSetdata<ProfilexColdex[]>>
    ) => {
      state.isLoading = false;
      state.profiles = payload.resultObject;
    },
    getProfilesError: (state, { payload }: PayloadAction<IPayloadSetError>) => {
      state.isLoading = false;
      state.dataError = payload.iserror;
      state.resultMessage = payload.error;
      state.shortMessage = payload.shorterror;
      state.open = payload.open!;
    },
    delete: (state) => {
      state.isLoading = true;
    },
    deleteSuccess: (
      state,
      { payload }: PayloadAction<IPayloadSetdata<ProfilexColdex>>
    ) => {
      state.isLoading = false;
      const index = state.userProfiles?.findIndex(up => up.id === payload.resultObject.id) ?? -1
      
      if (index !== -1) {
        state.userProfiles?.splice(index, 1)
      }
    },
    deleteError: (state, { payload }: PayloadAction<IPayloadSetError>) => {
      state.isLoading = false;
      state.dataError = payload.iserror;
      state.resultMessage = payload.error;
      state.shortMessage = payload.shorterror;
      state.open = payload.open!;
    },
    save: (state) => {
      state.isLoading = true;
    },
    saveSuccess: (
      state,
      { payload }: PayloadAction<IPayloadSetdata<ProfilexColdex>>
    ) => {
      state.isLoading = false;
      let s: ProfilexColdex[] | null | undefined ;

      if (state?.saved === null || state?.saved === undefined) {
        s = []
      } else {
        s = [...state.saved!]
      }

      s.push(payload.resultObject)
      
      state.saved = s
    },
    saveError: (state, { payload }: PayloadAction<IPayloadSetError>) => {
      state.isLoading = false;
      state.dataError = payload.iserror;
      state.resultMessage = payload.error;
      state.shortMessage = payload.shorterror;
      state.open = payload.open!;
    },
    getIdPolls: (state) => {
      state.isLoading = true;
    },
    getIdPollsSuccess: (
      state,
      { payload }: PayloadAction<IPayloadSetdata<string[]>>
    ) => {
      const ids = [...state.idPolls];
      ids.push(payload.resultObject);
      return {
        ...state,
        isLoading: false,
        idPolls: ids,
      }
    },
    getIdPollsError: (
      state,
      { payload }: PayloadAction<IPayloadSetError>
    ) => {
      state.isLoading = false;
      state.dataError = payload.iserror;
      state.resultMessage = payload.error;
      state.shortMessage = payload.shorterror;
      state.open = payload.open!;
    },
    saveSecurity: (state) => {
      state.isLoading = true;
    },
    saveSecuritySuccess: (state) => {
      state.isLoading = false;
      state.securitySaved = true;
    },
    saveSecurityError: (state, { payload }: PayloadAction<IPayloadSetError>) => {
      state.isLoading = false;
      state.dataError = payload.iserror;
      state.resultMessage = payload.error;
      state.shortMessage = payload.shorterror;
      state.open = payload.open!;
    },
  },
});

export default profileViewSlice;
