import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPartnerToEvaluateViewState } from "../../../utils/interfaces/store/IStore";
import { IPayloadSetdata, IPayloadSetError } from "../../Interfaces";
import { Category } from "../../../utils/types/Categorys";
import { PartnerEvaluated } from "../../../utils/types/PartnerEvaluated";
import { Company } from "../../../utils/types/Company";
import { ProfilexColdex } from "../../../utils/types/ProfilexColdex";
import { LastPartnerEvaluated } from "../../../utils/types/LastPartnerEvaluated";

const initialState: IPartnerToEvaluateViewState = {
  userPartners: [],
  categories: [],
  selectedCategories: [],
  companyTypes: [],
  lastEvaluatedCompanies: [],
  partners: [],
  selectedPartners: [],
  shortMessage: "",
  resultMessage: "",
  dataError: false,
  open: false,
  isLoading: false,
  isOpen: false,
  executed: false,
  securitySaved: false,
  done: false,
};

export const partnerToEvaluateViewSlice = createSlice({
  name: "partnerToEvaluateView",
  initialState,
  reducers: {
    clean: (state) => {
      return { ...initialState };
    },
    selectCategory: (state, { payload }: PayloadAction<Category[]>) => {
      state.selectedCategories = payload;
    },
    selectCompanyType: (state, { payload }: PayloadAction<string | undefined>) => {
      state.selectedCompanyType = payload;
    },
    select: (state, { payload }: PayloadAction<PartnerEvaluated>) => {
      const index = state.selectedPartners.findIndex(
        ({ code, ndoc }) => code === payload.code && ndoc === payload.ndoc
      );
      if (index === -1) {
        state.selectedPartners.push(payload);
      } else {
        state.selectedPartners.splice(index, 1);
      }
    },
    selectMany: (state, { payload }: PayloadAction<LastPartnerEvaluated[]>) => {
      let partners: PartnerEvaluated[] = [];
      if (payload.length > 0) {
        partners = state.partners.filter(({ ndoc,tdoc, code }) => payload.some(company => company.ndoc === ndoc && company.tdoc === tdoc && company.code === code ) && !state.selectedPartners.some(p => p.ndoc === ndoc && p.tdoc === tdoc && p.code === code));
      }
      
      state.selectedPartners = partners;
    },
    switchIsOpen: (state) => {
      state.isOpen = !state.isOpen;
    },
    getUserPartners: (state) => {
      state.isLoading = true;
    },
    getUserPartnersSuccess: (
      state,
      { payload }: PayloadAction<IPayloadSetdata<any[]>>
    ) => {
      state.isLoading = false;
      state.userPartners = payload.resultObject;
      state.executed = true;
    },
    getUserPartnersError: (state, { payload }: PayloadAction<IPayloadSetError>) => {
      state.isLoading = false;
      state.dataError = payload.iserror;
      state.resultMessage = payload.error;
      state.shortMessage = payload.shorterror;
      state.open = payload.open!;
    },
    getCategories: (state) => {
      state.isLoading = true;
    },
    getCategoriesSuccess: (
      state,
      { payload }: PayloadAction<IPayloadSetdata<Category[]>>
    ) => {
      state.isLoading = false;
      state.categories = payload.resultObject.filter(({code}) => code !== 'AUE' && code !== 'AEBL');
    },
    getCategoriesError: (
      state,
      { payload }: PayloadAction<IPayloadSetError>
    ) => {
      state.isLoading = false;
      state.dataError = payload.iserror;
      state.resultMessage = payload.error;
      state.shortMessage = payload.shorterror;
      state.open = payload.open!;
    },
    getCompanyTypes: (state) => {
      state.isLoading = true;
    },
    getCompanyTypesSuccess: (
      state,
      { payload }: PayloadAction<IPayloadSetdata<string[]>>
    ) => {
      state.isLoading = false;
      state.companyTypes = payload.resultObject;
    },
    getLastEvaluatedCompanies: (state) => {
      state.isLoading = true;
    },
    getLastEvaluatedCompaniesSuccess: (
      state,
      { payload }: PayloadAction<IPayloadSetdata<LastPartnerEvaluated[]>>
    ) => {
      state.isLoading = false;
      state.lastEvaluatedCompanies = payload.resultObject.filter(({code, ndoc}) => state.partners.find(p => p.code === code && p.ndoc === ndoc));
    },
    getCompanyTypesError: (
      state,
      { payload }: PayloadAction<IPayloadSetError>
    ) => {
      state.isLoading = false;
      state.dataError = payload.iserror;
      state.resultMessage = payload.error;
      state.shortMessage = payload.shorterror;
      state.open = payload.open!;
    },
    getPartners: (state) => {
      state.isLoading = true;
    },
    getPartnersSuccess: (
      state,
      { payload }: PayloadAction<IPayloadSetdata<PartnerEvaluated[]>>
    ) => {
      state.isLoading = false;
      state.partners = payload.resultObject.filter(({code}) => code !== 'AUE' && code !== 'AEBL');
    },
    getPartnersError: (state, { payload }: PayloadAction<IPayloadSetError>) => {
      state.isLoading = false;
      state.dataError = payload.iserror;
      state.resultMessage = payload.error;
      state.shortMessage = payload.shorterror;
      state.open = payload.open!;
    },
    save: (state) => {
      state.isLoading = true;
      state.executed = true;
    },
    saveSuccess: (
      state,
      { payload }: PayloadAction<IPayloadSetdata<PartnerEvaluated>>
    ) => {
      state.isLoading = false;
      let s: PartnerEvaluated[] | null | undefined ;

      if (state?.savedPartners === null || state?.savedPartners === undefined) {
        s = []
      } else {
        s = [...state.savedPartners!]
      }

      s.push(payload.resultObject)
      
      state.savedPartners = s
    },
    saveError: (state, { payload }: PayloadAction<IPayloadSetError>) => {
      state.isLoading = false;
      state.dataError = payload.iserror;
      state.resultMessage = payload.error;
      state.shortMessage = payload.shorterror;
      state.open = payload.open!;
    },
    saveSecurity: (state) => {
      state.isLoading = true;
    },
    saveSecuritySuccess: (state) => {
      state.isLoading = false;
      state.securitySaved = true;
      state.done = true;
    },
    saveSecurityError: (state, { payload }: PayloadAction<IPayloadSetError>) => {
      state.isLoading = false;
      state.dataError = payload.iserror;
      state.resultMessage = payload.error;
      state.shortMessage = payload.shorterror;
      state.open = payload.open!;
    },
  },
});

export default partnerToEvaluateViewSlice;
