import { Action, Dispatch } from '@reduxjs/toolkit';
import { ColdexAPI } from '../../../apis/apiColdex/ColdexAPI';
import { MESSAGE_ACTION_TYPE_CREATE, MESSAGE_ACTION_TYPE_DELETE, MESSAGE_ACTION_TYPE_GET, MESSAGE_CONTEXT_SECUSERXCOLDEX, MESSAGE_ERROR_TIMEOUT } from '../../../utils/constants/message-constants';
import { IApiResult } from '../../../utils/interfaces/store/IApiResponse';
import { Security } from '../../../utils/types/Security';
import {  startLoading,setUserxColdexSlice,setError,endLoading,addSecurityUserxColdexLocal,deleteSecurityUserxColdexLocal} from './securityxUserxColdexSlice';

/**
  * Esta es una función de TypeScript React que publica una lista de usuarios de seguridad en un extremo de la API de Coldex y envía acciones de carga y error según la respuesta.
  * @param users: una matriz de objetos de tipo Seguridad, que representa a los usuarios que se agregarán al sistema Coldex.
  * @param [aux=false]: el parámetro "aux" es un indicador booleano que determina si agregar o no el primer usuario en la matriz de "usuarios" al estado local después de publicar correctamente en la API. Si "aux" es verdadero, es porque no se agrego desde plantilla
  * @returns Una función que toma una matriz de objetos de seguridad y un valor booleano como parámetros, y devuelve una función asíncrona que envía acciones para iniciar y finalizar la carga, y realiza una solicitud POST a un extremo de ColdexAPI con los datos proporcionados. Si el parámetro `aux` es verdadero, también envía una acción para agregar el primer objeto de seguridad al estado local.
  */
export const postUsersxSecurityxColdex = (users:Array<Security>,aux=false) => {
    return async( dispatch:Dispatch<Action> ) => {
        dispatch( startLoading() );
        try {
            let count: number = 0;
            let usersFragment : Array<Array<Security>> = segmentUserList(users)
            if (usersFragment.length === 0) {
                dispatch(endLoading());
            }
            usersFragment.forEach(async userFragment => {
                const {data}= await ColdexAPI.post<IApiResult<Array<Security>>>(`adminColdex/SecurityxUserxColdex/List`,userFragment);
                if(!data.dataError){
                  count++
                }else{ 
                }
                if(count === usersFragment.length){
                    if (data.dataError) {
                        dispatch(setError({ error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_SECUSERXCOLDEX}`, iserror: true, shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_SECUSERXCOLDEX}`, open: true }));
                    } else {
                        dispatch(endLoading());
                    }
                    if(aux){
                        dispatch(addSecurityUserxColdexLocal(users[0]))
                     }
                    dispatch(endLoading());
                }
            })
        } catch (error) {
            dispatch(setError({ error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_SECUSERXCOLDEX}`, iserror: false, shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_SECUSERXCOLDEX}`, open: true }));
        }
    }    
}

/**
  * Esta función recupera una lista de objetos de seguridad asociados con un usuario específico y una ID de Coldex de una API y envía el resultado a una tienda Redux.
  * @param {string} email: una cadena que representa el correo electrónico del usuario que se recuperará.
  * @param {number} idcoldex - El parámetro `idcoldex` es un número que representa la ID de un Coldex
  * @returns La función `getUsers` devuelve una función que toma un parámetro `dispatch` y una Promesa. La Promesa resuelve enviar una acción con el resultado de una solicitud GET, pasando un correo electrónico e idcoldex como parámetros. Si tiene éxito, el objeto de resultado de la respuesta se pasa a la acción `setUserxColdexSlice`.
  */
export const getUsers = (email:string , idcoldex:number) => {
    return async( dispatch:Dispatch<Action>) => {
        dispatch( startLoading() );
        try {
            const { data } = await ColdexAPI.get<IApiResult<Array<Security>>>(`adminColdex/SecurityxUserxColdex/${idcoldex}?&email=${email}`);
            dispatch(setUserxColdexSlice({resultObject:data.resultObject}));
        } catch (error) {
            dispatch(setError({ error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_SECUSERXCOLDEX}`, iserror: false, shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_SECUSERXCOLDEX}`, open: true }));
        }
    }    
}

/**
  * Esta función eliminae la seguridad de usuerio en un sistema Coldex y maneja cualquier error que pueda ocurrir.
  * @param {Security} user: el parámetro de usuario es un objeto de tipo Security, que se pasa como datos al método de eliminación de ColdexAPI. También se utiliza como argumento para el creador de la acción deleteSecurityUserxColdexLocal.
  * @returns Una función que toma un objeto de "user" de tipo "Security" como entrada y devuelve una función asincrónica que toma una función de "dispatch" de tipo "Dispatch<Action>" como entrada.
  */
export const deleteUser = (user:Security) => {
    return async( dispatch:Dispatch<Action>) => {
        dispatch( startLoading() )
        try {
            const { data } = await ColdexAPI.delete<IApiResult<Array<Security>>>(`adminColdex/SecurityxUserxColdex`,{data:user});
            if(data.dataError){
                dispatch(setError({ error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_DELETE} en ${MESSAGE_CONTEXT_SECUSERXCOLDEX}`, iserror: true, shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_SECUSERXCOLDEX}`, open: true }));
            }else{
                dispatch(deleteSecurityUserxColdexLocal(user))
                dispatch(endLoading())
            }
        } catch (error) {
            dispatch(setError({ error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_DELETE} en ${MESSAGE_CONTEXT_SECUSERXCOLDEX}`, iserror: false, shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_SECUSERXCOLDEX}`, open: true }));
        }
    }    
}
/**
 * Esta función restablece el estado de error en una tienda Redux.
  * @returns La función `resetError` está devolviendo una función asíncrona que toma un `dispatch`
  * Funciona como un argumento.
 */
export const resetError = () => {
    return async (dispatch: Dispatch<Action>) => {
        dispatch(setError({ error: "", iserror: false, shorterror: "", open: false }));
    }
}
/**
 * La función segmenta un array dado de usuarios en arrays más pequeños de un tamaño específico.
  * @param userSecColdex: un array de objetos de permisos de usuario que debe segmentarse en arrays más pequeñas.
  * @devuelve un array de arrays, donde cada array interno contiene un segmento del array `userColdex` original
  * con una longitud máxima de 200.
 */

function segmentUserList(userSecColdex : Array<Security>){
    const fragmentCount = 1000
    let usersArraysList : Array<Array<Security>> = []
    for (let index = 0; index < userSecColdex.length; index += fragmentCount) {
      let userColdexArray: Array<Security> = userSecColdex.slice(index, index+fragmentCount)
      usersArraysList.push(userColdexArray)
    }
  return usersArraysList
}
