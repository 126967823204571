import { Action, Dispatch } from "@reduxjs/toolkit";
import { ColdexAPI } from "../../../apis/apiColdex/ColdexAPI";
import { IApiResult } from "../../../utils/interfaces/store/IApiResponse";
import partnerToEvaluateViewSlice from "./partnerToEvaluateView";
import { Category } from "../../../utils/types/Categorys";
import { PartnerEvaluated } from "../../../utils/types/PartnerEvaluated";
import { Company } from "../../../utils/types/Company";
import { ProfilexColdex } from "../../../utils/types/ProfilexColdex";
import { LastPartnerEvaluated } from "../../../utils/types/LastPartnerEvaluated";

const partnerToEvaluateView = {
  clean: () => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(partnerToEvaluateViewSlice.actions.clean());
    };
  },
  selectCategory: (categories: Category[]) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(partnerToEvaluateViewSlice.actions.selectCategory(categories));
    };
  },
  selectCompanyType: (companyType?: string) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(
        partnerToEvaluateViewSlice.actions.selectCompanyType(companyType)
      );
    };
  },
  select: (partner: PartnerEvaluated) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(partnerToEvaluateViewSlice.actions.select(partner));
    };
  },
  selectMany: (partnersIds: LastPartnerEvaluated[]) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(partnerToEvaluateViewSlice.actions.selectMany(partnersIds));
    };
  },
  switchIsOpen: () => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(partnerToEvaluateViewSlice.actions.switchIsOpen());
    };
  },
  getUserPartners: (coldexId: number, userId: number) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(partnerToEvaluateViewSlice.actions.getUserPartners());

      ColdexAPI.get<IApiResult<any[]>>(
        `adminColdex/coldex/${coldexId}/user/${userId}/partner`
      )
        .then(({ data }) => {
          if (data.dataError) {
            dispatch(
              partnerToEvaluateViewSlice.actions.getUserPartnersError({
                iserror: true,
                open: true,
                shorterror: `Error short`,
                error: `Error`,
              })
            );
            return;
          }

          dispatch(
            partnerToEvaluateViewSlice.actions.getUserPartnersSuccess({
              resultObject: data.resultObject,
            })
          );
        })
        .catch((error) => {
          dispatch(
            partnerToEvaluateViewSlice.actions.getUserPartnersError({
              iserror: true,
              open: true,
              shorterror: `Error short`,
              error: `Error`,
            })
          );
        });
    };
  },
  getCategories: (coldexId: number) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(partnerToEvaluateViewSlice.actions.getCategories());

      ColdexAPI.get<IApiResult<Category[]>>(
        `adminColdex/coldex/CategoriesxColdex/${coldexId}`
      )
        .then(({ data }) => {
          if (data.dataError) {
            dispatch(
              partnerToEvaluateViewSlice.actions.getCategoriesError({
                iserror: true,
                open: true,
                shorterror: `Error short`,
                error: `Error`,
              })
            );
            return;
          }

          dispatch(
            partnerToEvaluateViewSlice.actions.getCategoriesSuccess({
              resultObject: data.resultObject,
            })
          );
        })
        .catch((error) => {
          dispatch(
            partnerToEvaluateViewSlice.actions.getCategoriesError({
              iserror: true,
              open: true,
              shorterror: `Error short`,
              error: `Error`,
            })
          );
        });
    };
  },
  getCompanyTypes: (coldexId: number, userId: number) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(partnerToEvaluateViewSlice.actions.getCompanyTypes());

      ColdexAPI.get<IApiResult<string[]>>(
        `adminColdex/CompanyXIndustryXColdex/coldex/${coldexId}/companyType?IDUser=${userId}`
      )
        .then(({ data }) => {
          if (data.dataError) {
            dispatch(
              partnerToEvaluateViewSlice.actions.getCompanyTypesError({
                iserror: true,
                open: true,
                shorterror: `Error short`,
                error: `Error`,
              })
            );
            return;
          }

          dispatch(
            partnerToEvaluateViewSlice.actions.getCompanyTypesSuccess({
              resultObject: data.resultObject,
            })
          );
        })
        .catch((error) => {
          dispatch(
            partnerToEvaluateViewSlice.actions.getCompanyTypesError({
              iserror: true,
              open: true,
              shorterror: `Error short`,
              error: `Error`,
            })
          );
        });
    };
  },
    getLastEvaluatedCompanies: (userId: number, coldexToExclude: number) => {
      return async (dispatch: Dispatch<Action>) => {
        dispatch(partnerToEvaluateViewSlice.actions.getLastEvaluatedCompanies());
  
        ColdexAPI.post<IApiResult<LastPartnerEvaluated[]>>(
          `adminColdex/Company/get-last-selection?userId=${userId}&coldexId=${coldexToExclude}`
        )
          .then(({ data }) => {
            if (data.dataError) {
              dispatch(
                partnerToEvaluateViewSlice.actions.getCompanyTypesError({
                  iserror: true,
                  open: true,
                  shorterror: `Error short`,
                  error: `Error`,
                })
              );
              return;
            }
  
            dispatch(
              partnerToEvaluateViewSlice.actions.getLastEvaluatedCompaniesSuccess({
                resultObject: data.resultObject,
              })
            );
          })
          .catch((error) => {
            dispatch(
              partnerToEvaluateViewSlice.actions.getCompanyTypesError({
                iserror: true,
                open: true,
                shorterror: `Error short`,
                error: `Error`,
              })
            );
          });
      };
    },
  getPartners: (
    coldexId: number,
    companyType?: string,
    categories?: string[]
  ) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(partnerToEvaluateViewSlice.actions.getPartners());

      let query = `adminColdex/CompanyXIndustryXColdex/coldex/${coldexId}/company`;

      const params = new URLSearchParams();

      if (companyType) {
        params.append("companyType", companyType);
      }

      if (categories && categories.length > 0) {
        params.append("category", categories.join(","));
      }

      if (params.toString()) {
        query += `?${params.toString()}`;
      }
      ColdexAPI.get<IApiResult<PartnerEvaluated[]>>(query)
        .then(({ data }) => {
          if (data.dataError) {
            dispatch(
              partnerToEvaluateViewSlice.actions.getPartnersError({
                iserror: true,
                open: true,
                shorterror: `Error short`,
                error: `Error`,
              })
            );
            return;
          }

          dispatch(
            partnerToEvaluateViewSlice.actions.getPartnersSuccess({
              resultObject: data.resultObject,
            })
          );
        })
        .catch((error) => {
          dispatch(
            partnerToEvaluateViewSlice.actions.getPartnersError({
              iserror: true,
              open: true,
              shorterror: `Error short`,
              error: `Error`,
            })
          );
        });
    };
  },
  save: (coldexId: number, userId: number, partner: PartnerEvaluated) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(partnerToEvaluateViewSlice.actions.save());

      ColdexAPI.post<IApiResult<void>>(`adminColdex/coldex/${coldexId}/user/${userId}/partner`, {
        IDColdex: coldexId,
        idUser: userId,
        category: partner.code,
        partner: partner.ndoc,
      })
        .then(({ data }) => {
          if (data.dataError) {
            dispatch(
              partnerToEvaluateViewSlice.actions.saveError({
                iserror: true,
                open: true,
                shorterror: `Error short`,
                error: `Error`,
              })
            );
            return;
          }

          dispatch(
            partnerToEvaluateViewSlice.actions.saveSuccess({
              resultObject: partner,
            })
          );
        })
        .catch((error) => {
          dispatch(
            partnerToEvaluateViewSlice.actions.saveError({
              iserror: true,
              open: true,
              shorterror: `Error short`,
              error: `Error`,
            })
          );
        });
    };
  },
  saveSecurity: (userId: number, coldexId: number) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(partnerToEvaluateViewSlice.actions.saveSecurity());

      ColdexAPI.post<IApiResult<void>>(`adminColdex/SecurityxUserxColdex/${coldexId}?IdUser=${userId}`)
        .then(({ data }) => {
          if (data.dataError) {
            dispatch(
              partnerToEvaluateViewSlice.actions.saveSecurityError({
                iserror: true,
                open: true,
                shorterror: `Error short`,
                error: `Error`,
              })
            );
            return;
          }

          dispatch(partnerToEvaluateViewSlice.actions.saveSecuritySuccess());
        })
        .catch((error) => {
          dispatch(
            partnerToEvaluateViewSlice.actions.saveSecurityError({
              iserror: true,
              open: true,
              shorterror: `Error short`,
              error: `Error`,
            })
          );
        });
    };
  },
};

export default partnerToEvaluateView;
