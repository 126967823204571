import { DownloadSharp } from "@mui/icons-material";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/Hooks";
import { getTemplete } from "../../store/slices/templeteFile";
import { RootState } from "../../store/Store";
import {
  NAME_TEMPLATE_RED,
  NAME_TEMPLATE_SECTORIAL,
  NAME_TEMPLATE_PUBLICO,
  TITLE_COLDEX_SECTION_RED,
  TITLE_COLDEX_SECTION_SECTORIAL,
  TITLE_COLDEX_SECTION_PUBLICO,
} from "../../utils/constants/text-constants";
import useFileFecth from "../../utils/hooks/files/useFileFecth";
import usePermissionsColdex from "../../utils/hooks/permissions/usePermissionsColdex";
import { ITitlePage } from "../../utils/interfaces/app/ITitles";
import { IconSelect } from "./components/IconSelect";
import "./titles.scss";

/**  Este código exporta un componente de React llamado `PageTitle` que incluye cuatro accesorios: `title`, `icon`,
 * `buttonBack` y `flag`. A continuación, muestra una sección de título con el "title" y el "icon" dados, y
 * incluye opciones como un botón de descarga para ciertos tipos de páginas
 * (`TITLE_COLDEX_SECTION_SECTORIAL`, `TITLE_COLDEX_SECTION_RED` y `TITLE_COLDEX_SECTION_PUBLICO`).
 * También incluye un botón de retroceso si `buttonBack` es verdadero. El componente utiliza varios hooks como
 * `useEffect`, `useAppDispatch` y `useAppSelector` para interactuar con la tienda Redux y manejar al usuario
 * permisos
 * */

export const PageTitle = ({
  title,
  icon,
  buttonBack,
  flag,
  children,
}: ITitlePage) => {
  const linkDownload: HTMLAnchorElement | undefined = useFileFecth(title);
  const { permissionRolUser } = useAppSelector(
    (state: RootState) => state.permission
  );
  const { flagDownload } = usePermissionsColdex(permissionRolUser);
  const { language } = useAppSelector(
    (state: RootState) => state.multiLanguage
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (title.includes(TITLE_COLDEX_SECTION_SECTORIAL)) {
      dispatch(getTemplete(NAME_TEMPLATE_SECTORIAL)).finally(() => {});
    } else if (
      title.includes(TITLE_COLDEX_SECTION_RED) ||
      title.includes(language.views.coldexView.titles.TITLE_COLDEX_SECTION_RED)
    ) {
      dispatch(getTemplete(NAME_TEMPLATE_RED)).finally(() => {});
    } else if (
      title.includes(TITLE_COLDEX_SECTION_PUBLICO) ||
      title.includes(
        language.views.coldexView.titles.TITLE_COLDEX_SECTION_PUBLICO
      )
    ) {
      dispatch(getTemplete(NAME_TEMPLATE_PUBLICO)).finally(() => {});
    }
  }, [title]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleDownloadTempleteFile = (
    e: React.MouseEvent<Element, MouseEvent>
  ) => {
    e.preventDefault();
    if (linkDownload !== undefined) {
      linkDownload.click();
      linkDownload.remove();
    }
  };
  return (
    <div className="title-content">
      <div className="title-content__title">
        <h1>
          <IconSelect key={icon} icon={icon} />
          {title}
        </h1>
      </div>

      {children ?? (
        <div className="title-content__Options">
          {title.includes(TITLE_COLDEX_SECTION_SECTORIAL) ||
          title.includes(
            language.views.coldexView.titles.TITLE_COLDEX_SECTION_SECTORIAL
          ) ||
          title.includes(TITLE_COLDEX_SECTION_RED) ||
          title.includes(TITLE_COLDEX_SECTION_PUBLICO) ||
          title.includes(
            language.views.coldexView.titles.TITLE_COLDEX_SECTION_RED
          ) ||
          title.includes(
            language.views.coldexView.titles.TITLE_COLDEX_SECTION_PUBLICO
          ) ? (
            <>
              {!flag && (
                <button
                  className="title-content__Options_download"
                  onClick={(e) => handleDownloadTempleteFile(e)}
                  disabled={!flagDownload ? true : false}
                >
                  <DownloadSharp fontSize="small" />{" "}
                  {language.components.titles.common.buttons.BTN_TEMPLATE}
                </button>
              )}
            </>
          ) : (
            <></>
          )}
          {buttonBack && (
            <button className="title-content__Options_goback">
              {language.components.titles.common.buttons.BTN_BACK}
            </button>
          )}
        </div>
      )}
    </div>
  );
};
