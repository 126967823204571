import { Action, Dispatch } from "@reduxjs/toolkit";
import { ColdexAPI } from "../../../apis/apiColdex/ColdexAPI";
import { IApiResult } from "../../../utils/interfaces/store/IApiResponse";
import viewOfQuestionnaireToAnswerAUESlice from "./viewOfQuestionnaireToAnswerAUE";
import { RowDataColdex } from "../../../utils/types/RowDataColdex";
import { IColdexSecurity } from "../../../utils/types/UserColdex";
import { UserAnswerxColdex } from "../../../utils/types/UserAnswerxColdex";
import { Coldex } from "../../../utils/types/Coldex";
import { Company } from "../../../utils/types/Company";
import { ResponseType } from "../../../utils/types/ResponseType";
import { ProfilePollType } from "../../../utils/types/ProfilePollType";
import { ProfilexColdex } from "../../../utils/types/ProfilexColdex";

const viewOfQuestionnaireToAnswerAUEEffect = {
  clean: () => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(viewOfQuestionnaireToAnswerAUESlice.actions.clean());
    };
  },
  setInit: (profiles: ProfilexColdex[]) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(viewOfQuestionnaireToAnswerAUESlice.actions.setInit(profiles));
    };
  },
  getColdex: (idColdex: number) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(viewOfQuestionnaireToAnswerAUESlice.actions.getColdex());

      ColdexAPI.get<IApiResult<Coldex>>(
        `adminColdex/Coldex/GetColdexId/${idColdex}`
      )
        .then(({ data }) => {
          if (data.dataError) {
            dispatch(
              viewOfQuestionnaireToAnswerAUESlice.actions.getColdexError({
                iserror: true,
                shorterror: data.shortMessage,
                error: data.resultMessage,
              })
            );
            return;
          }

          dispatch(
            viewOfQuestionnaireToAnswerAUESlice.actions.getColdexSuccess(
              data.resultObject
            )
          );
        })
        .catch((error) => {
          dispatch(
            viewOfQuestionnaireToAnswerAUESlice.actions.getColdexError({
              iserror: true,
              shorterror: `Error short`,
              error,
            })
          );
        });
    };
  },
  getPermissions: (coldexSecurity: IColdexSecurity) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(viewOfQuestionnaireToAnswerAUESlice.actions.getPermissions());

      ColdexAPI.get<IApiResult<ProfilePollType[]>>(
        `adminColdex/role/1/pollType/GetPermission?IdColdex=${coldexSecurity.idColdex}&` +
          `IdPoll=${coldexSecurity.idPoll}&IdCategory=${coldexSecurity.idCategory}&` +
          `IdType=${coldexSecurity.idType}`
      )
        .then(({ data }) => {
          if (data.dataError) {
            dispatch(
              viewOfQuestionnaireToAnswerAUESlice.actions.getPermissionsError({
                iserror: true,
                shorterror: data.shortMessage,
                error: data.resultMessage,
              })
            );
            return;
          }

          dispatch(
            viewOfQuestionnaireToAnswerAUESlice.actions.getPermissionsSuccess(
              data.resultObject
            )
          );
        })
        .catch((error) => {
          dispatch(
            viewOfQuestionnaireToAnswerAUESlice.actions.getPermissionsError({
              iserror: true,
              shorterror: `Error short`,
              error,
            })
          );
        });
    };
  },
  getStructure: (coldexSecurity: IColdexSecurity) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(viewOfQuestionnaireToAnswerAUESlice.actions.getStructure());

      ColdexAPI.get(
        `adminColdex/PollxColdex/Security?IdColdex=${coldexSecurity.idColdex}&` +
          `IdPoll=${coldexSecurity.idPoll}&IdCategory=${coldexSecurity.idCategory}&` +
          `IdType=${coldexSecurity.idType}&Email=${coldexSecurity.email}`
      )
        .then(({ data }) => {
          if (data.dataError) {
            dispatch(
              viewOfQuestionnaireToAnswerAUESlice.actions.getStructureError({
                iserror: true,
                shorterror: data.shortMessage,
                error: data.resultMessage,
              })
            );
            return;
          }

          dispatch(
            viewOfQuestionnaireToAnswerAUESlice.actions.getStructureSuccess(
              JSON.parse(data.resultObject.response.replace(/[\x00-\x1F\x7F]/g, ''))
            )
          );
        })
        .catch((error) => {
          dispatch(
            viewOfQuestionnaireToAnswerAUESlice.actions.getStructureError({
              iserror: true,
              shorterror: `Error short`,
              error,
            })
          );
        });
    };
  },
  getAdvance: (idColdex: number, email: string, idPoll: string, idCategory: string) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(viewOfQuestionnaireToAnswerAUESlice.actions.getAdvance());

      ColdexAPI.get<IApiResult<UserAnswerxColdex[]>>(
        `adminColdex/UserAnswerxColdex/GetUserAnswerxColdex?idColdex=${idColdex}&` +
          `email=${email}`
      )
        .then(({ data }) => {
          if (data.dataError) {
            dispatch(
              viewOfQuestionnaireToAnswerAUESlice.actions.getAdvanceError({
                iserror: true,
                shorterror: data.shortMessage,
                error: data.resultMessage,
              })
            );
            return;
          }

          dispatch(
            viewOfQuestionnaireToAnswerAUESlice.actions.getAdvanceSuccess(
              data.resultObject.find((r) => r.idPoll === idPoll && r.idCategory === idCategory)!
            )
          );
        })
        .catch((error) => {
          dispatch(
            viewOfQuestionnaireToAnswerAUESlice.actions.getAdvanceError({
              iserror: true,
              shorterror: `Error short`,
              error,
            })
          );
        });
    };
  },
  getAnswers: (type: "SC" | "LR", coldexSecurity: IColdexSecurity) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(viewOfQuestionnaireToAnswerAUESlice.actions.getAnswers());

      ColdexAPI.get<IApiResult<RowDataColdex[]>>(
        `adminColdex/RowDataColdex${type === "LR" ? "LR" : ""}/Answers?` +
          `IdColdex=${coldexSecurity.idColdex}&` +
          `IdPoll=${coldexSecurity.idPoll}&IdCategory=${coldexSecurity.idCategory}&` +
          `IdType=${coldexSecurity.idType}&Email=${coldexSecurity.email}`
      )
        .then(({ data }) => {
          if (data.dataError) {
            dispatch(
              viewOfQuestionnaireToAnswerAUESlice.actions.getAnswersError({
                iserror: true,
                shorterror: data.shortMessage,
                error: data.resultMessage,
              })
            );
            return;
          }

          dispatch(
            viewOfQuestionnaireToAnswerAUESlice.actions.getAnswersSuccess(
              data.resultObject
            )
          );
        })
        .catch((error) => {
          dispatch(
            viewOfQuestionnaireToAnswerAUESlice.actions.getAnswersError({
              iserror: true,
              shorterror: `Error short`,
              error,
            })
          );
        });
    };
  },
  getResponseTypes: (idColdex: number) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(viewOfQuestionnaireToAnswerAUESlice.actions.getResponseTypes());

      ColdexAPI.get<IApiResult<ResponseType[]>>(
        `adminColdex/coldex/${idColdex}/responseType`
      )
        .then(({ data }) => {
          if (data.dataError) {
            dispatch(
              viewOfQuestionnaireToAnswerAUESlice.actions.getResponseTypesError({
                iserror: true,
                shorterror: data.shortMessage,
                error: data.resultMessage,
              })
            );
            return;
          }

          dispatch(
            viewOfQuestionnaireToAnswerAUESlice.actions.getResponseTypesSuccess(
              data.resultObject
            )
          );
        })
        .catch((error) => {
          dispatch(
            viewOfQuestionnaireToAnswerAUESlice.actions.getResponseTypesError({
              iserror: true,
              shorterror: `Error short`,
              error,
            })
          );
        });
    };
  },
  getCompanyTypes: (idColdex: number, category: string, ndocCompany: string) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(viewOfQuestionnaireToAnswerAUESlice.actions.getCompanyTypes());

      ColdexAPI.get<IApiResult<Company[]>>(
        `adminColdex/CompanyXIndustryXColdex/coldex/${idColdex}/company?category=${category}`
      )
        .then(({ data }) => {
          if (data.dataError) {
            dispatch(
              viewOfQuestionnaireToAnswerAUESlice.actions.getCompanyTypesError({
                iserror: true,
                shorterror: data.shortMessage,
                error: data.resultMessage,
              })
            );
            return;
          }

          dispatch(
            viewOfQuestionnaireToAnswerAUESlice.actions.getCompanyTypesSuccess(
              data?.resultObject?.filter(c => c?.ndoc === ndocCompany).map(c => c.companyType!)! ?? []
            )
          );
        })
        .catch((error) => {
          dispatch(
            viewOfQuestionnaireToAnswerAUESlice.actions.getCompanyTypesError({
              iserror: true,
              shorterror: `Error short`,
              error,
            })
          );
        });
    };
  },
  onChange: (value: RowDataColdex) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(viewOfQuestionnaireToAnswerAUESlice.actions.onChange(value));
    };
  },
  cleanChanges: (value: RowDataColdex[]) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(viewOfQuestionnaireToAnswerAUESlice.actions.cleanChanges(value));
    };
  },
  next: () => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(viewOfQuestionnaireToAnswerAUESlice.actions.next());
    };
  },
  previous: () => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(viewOfQuestionnaireToAnswerAUESlice.actions.previous());
    };
  },
  save: (type: "LR" | "SC", answers: RowDataColdex[]) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(viewOfQuestionnaireToAnswerAUESlice.actions.save());

      ColdexAPI.post<IApiResult<RowDataColdex[]>>(
        `adminColdex/RowDataColdex${type === "LR" ? "LR" : ""}`,
        answers
      )
        .then(({ data }) => {
          if (data.dataError) {
            dispatch(
              viewOfQuestionnaireToAnswerAUESlice.actions.saveError({
                iserror: true,
                shorterror: data.shortMessage,
                error: data.resultMessage,
              })
            );
            return;
          }

          dispatch(
            viewOfQuestionnaireToAnswerAUESlice.actions.saveSuccess(
              data.resultObject
            )
          );
        })
        .catch((error) => {
          dispatch(
            viewOfQuestionnaireToAnswerAUESlice.actions.saveError({
              iserror: true,
              shorterror: `Error short`,
              error,
            })
          );
        });
    };
  },
  saveAdvance: (advance: UserAnswerxColdex) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(viewOfQuestionnaireToAnswerAUESlice.actions.saveAdvance());

      ColdexAPI.post<IApiResult<UserAnswerxColdex>>(
        `adminColdex/UserAnswerxColdex`,
        advance
      )
        .then(({ data }) => {
          if (data.dataError) {
            dispatch(
              viewOfQuestionnaireToAnswerAUESlice.actions.saveAdvanceError({
                iserror: true,
                shorterror: data.shortMessage,
                error: data.resultMessage,
              })
            );
            return;
          }

          dispatch(
            viewOfQuestionnaireToAnswerAUESlice.actions.saveAdvanceSuccess(advance)
          );
        })
        .catch((error) => {
          dispatch(
            viewOfQuestionnaireToAnswerAUESlice.actions.saveAdvanceError({
              iserror: true,
              shorterror: `Error short`,
              error,
            })
          );
        });
    };
  },
};

export default viewOfQuestionnaireToAnswerAUEEffect;
