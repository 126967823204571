import React from "react";
import "./button.scss";

type IBtnType = "button" | "outline";

type IButton = {
  btnType?: IBtnType;
  children?: React.ReactNode;
  [x: string]: any;
};

/**
 * Esta es una función de TypeScript React que representa un componente de botón con elementos secundarios y accesorios adicionales.
 * @param {IButton}: el código anterior es un componente funcional de React que exporta un elemento de botón con algunos accesorios.
 * @returns Se devuelve un componente funcional de React llamado "Button". Toma un objeto de tipo IButton como props, que incluye una propiedad "children" y cualquier otra propiedad que se pueda pasar al usar el operador de extensión. El componente devuelve un elemento de botón con la clase "Button" y los accesorios se pasan usando el operador de propagación. Los elementos secundarios del componente también se representan dentro del elemento de botón.
 */
export default function Button({ btnType, children, ...rest }: IButton) {
  return (
    <button {...rest} className={btnType ?? "button"}>
      {children}
    </button>
  );
}
