import { lazy, Suspense } from "react";
import { Route } from "react-router-dom";
import RouterContainer from "../components/Hoc/RouterContainer";
import ColdexPublicView from "../views/ColdexView/components/ColdexPublicView";
import { Loading } from "../components/Loading/Loading";
import UserProfile from "../views/UserProfile/UserProfile";
import PartnerToEvaluateView from "../views/PartnerToEvaluateView/PartnerToEvaluateView";

const AccountActiveView = lazy(() => import("../views/AccountActiveView/AccountActiveView"));
const AdminView = lazy(() => import("../views/AdminView/AdminView"));
const AdminHomeView = lazy(() => import("../views/AdminView/components/AdminHomeView"));
const BusinessView = lazy(() => import("../views/AdminView/components/BusinessView/BusinessView"));
const RoleView = lazy(() => import("../views/AdminView/components/RoleView/RoleView"));
const UsersView = lazy(() => import("../views/AdminView/components/UsersView/UsersView"));
const ColdexView = lazy(() => import("../views/ColdexView/ColdexView"));
const ColdexHomeView = lazy(() => import("../views/ColdexView/components/ColdexHomeView"));
const ColdexRedView = lazy(() => import("../views/ColdexView/components/ColdexRedView"));
const ColdexSectorialView = lazy(() => import("../views/ColdexView/components/ColdexSectorialView"));
const DetailsColdexView = lazy(() => import("../views/ColdexView/components/DetailsColdexView"));
const LoginView = lazy(() => import("../views/LoginView/LoginView"));
const PasswordRecoveryView = lazy(() => import("../views/PasswordRecoveryView/PasswordRecoveryView"));
const MainView = lazy(() => import("../views/MainView/MainView"));
const PollsView = lazy(() => import("../views/PollsView/PollsView"));
const ReportsView = lazy(() => import("../views/ReportsView/ReportsView"));
const ReportsViewLR = lazy(() => import("../views/ReportsLRView/ReportsLRView"));
const UserHomeColdexView = lazy(() => import("../views/UserView/components/UserHomeColdexView"));
const UserColdexView = lazy(() => import("../views/UserView/UserColdexView"));
const SectionView = lazy(() => import("../views/AdminView/components/ResourcesView/SectionView"));
const ResourceConfigView = lazy(() => import("../views/AdminView/components/ResourcesView/SectionResources"));
const MainHomeView = lazy(() => import('../views/MainView/components/MainHomeView'));
const MonitoringReportView = lazy(() => import('../views/MonitoringReportView/MonitoringReportView'))
const MonitoringReportViewLR = lazy(() => import('../views/MonitoringReportViewLR/MonitoringReportViewLR'))
const UserResourcesView = lazy(() => import('../views/UserView/components/UserResourcesView'))
const UserSectionsView = lazy(() => import('../views/UserView/components/UserSectionsView'))



export const routes = [
<Route path="/login" element={<Suspense fallback={<Loading/>}><LoginView></LoginView></Suspense>} key="login" />,
<Route path="/passwordRecovery"element={<Suspense fallback={<Loading/>}><PasswordRecoveryView></PasswordRecoveryView></Suspense>}key="passwordRecovery"/>,
<Route path="/AccountActive" element={<Suspense fallback={<Loading/>}><AccountActiveView></AccountActiveView></Suspense>} key="accountActive"/>,
<Route element={<RouterContainer></RouterContainer>} key="hoc">
  <Route path="/" element={<Suspense fallback={<Loading/>}><MainView></MainView></Suspense>} key="main">
    <Route path="" element={<Suspense fallback={<Loading/>}><MainHomeView></MainHomeView></Suspense>} key="mainHomeView"></Route>
    <Route path="administracion" element={<Suspense fallback={<Loading/>}><AdminView></AdminView></Suspense>}key="admin" >
      <Route path="" element={<Suspense fallback={<Loading/>}><AdminHomeView></AdminHomeView></Suspense>} key="mainHomeView"></Route>
      <Route path="usuarios" element={<Suspense fallback={<Loading/>}><UsersView></UsersView></Suspense>}key="usuarios"></Route>
      <Route path="empresas" element={<Suspense fallback={<Loading/>}><BusinessView></BusinessView></Suspense>}key="empresas"></Route>
      <Route path="roles" element={<Suspense fallback={<Loading/>}><RoleView></RoleView></Suspense>}key="roles"></Route>
      <Route path="secciones"><Route path=""element={<Suspense fallback={<Loading/>}><SectionView></SectionView></Suspense>}key="secciones"/>
        <Route path="Configuracion/:type/:idSection" element={<Suspense fallback={<Loading/>}><ResourceConfigView></ResourceConfigView></Suspense>}key="recursos"/></Route>
    </Route>
    <Route path="coldex" element={<Suspense fallback={<Loading/>}><ColdexView></ColdexView></Suspense>} key="coldex">
      <Route path="" element={<Suspense fallback={<Loading/>}><ColdexHomeView></ColdexHomeView></Suspense>} key= "coldexHomeView"></Route>
      <Route path="red" key= "lrView">
        <Route path="" element={<Suspense fallback={<Loading/>}><ColdexRedView></ColdexRedView></Suspense>} key= "coldexRedView"/>
        <Route path="reporte/:idcoldex" key=":idcoldex" element={<Suspense fallback={<Loading/>}><ReportsViewLR /></Suspense>}/>
        <Route path=":idcoldex" element={<Suspense fallback={<Loading/>}><DetailsColdexView /></Suspense>} key= "DetailsView"/>
        <Route path="monitoring_report" element={<Suspense fallback={<Loading/>}><MonitoringReportViewLR></MonitoringReportViewLR></Suspense>}  key= "monitoring_reportLR"/>,
      </Route >
      <Route path="sectorial" key= "sectorialView"> 
        <Route path="" element={<Suspense fallback={<Loading/>}><ColdexSectorialView></ColdexSectorialView></Suspense>} key="sectorial"/>
        <Route path="reporte/:idcoldex" key=":idcoldex" element={<Suspense fallback={<Loading/>}><ReportsView/></Suspense>} />
        <Route path=":idcoldex" key=":idcoldex" element={<Suspense fallback={<Loading/>}><DetailsColdexView/></Suspense>} />
        <Route path="monitoring_report" element={<Suspense fallback={<Loading/>}><MonitoringReportView></MonitoringReportView></Suspense>} key= "monitoring_reportS" />,
      </Route>
      <Route path="publico" key= "publicAUE" > 
        <Route path="" element={<Suspense fallback={<Loading/>}><ColdexPublicView></ColdexPublicView></Suspense>} key="publicView"/>
        <Route path="reporte/:idcoldex" key=":idcoldex" element={<Suspense fallback={<Loading/>}><ReportsView/></Suspense>} />
        <Route path=":idcoldex" key=":idcoldex" element={<Suspense fallback={<Loading/>}><DetailsColdexView/></Suspense>} />
        <Route path="monitoring_report" element={<Suspense fallback={<Loading/>}><MonitoringReportView></MonitoringReportView></Suspense>} key= "monitoring_reportP" />,

      </Route>
    </Route>
    <Route path="reportes" element={<Suspense fallback={<Loading/>}></Suspense>}key="empresas">
      <Route path="graficos" element={<Suspense fallback={<Loading/>}><UsersView></UsersView></Suspense>}key="graficos"></Route>
    </Route>
    <Route path="user" key="userColdex" element={<Suspense fallback={<Loading/>}><UserHomeColdexView></UserHomeColdexView></Suspense>}>
      <Route path="" element={<Suspense fallback={<Loading/>}><MainHomeView></MainHomeView></Suspense>}/>,
      <Route path="recursos" element={<Suspense fallback={<Loading/>}><UserSectionsView></UserSectionsView></Suspense>}/>,
      <Route path="recursos/:type/:shortName/:idSection" element={<Suspense fallback={<Loading/>}><UserResourcesView></UserResourcesView></Suspense>}/>,
      <Route path="coldex/:idcoldex" element={<Suspense fallback={<Loading/>}><UserColdexView></UserColdexView></Suspense>}/>,
      <Route path="coldex/:idcoldex/profile" element={<Suspense fallback={<Loading/>}><UserProfile /></Suspense>}/>,
      <Route path="coldex/:idcoldex/partnerToEvaluate" element={<Suspense fallback={<Loading/>}><PartnerToEvaluateView /></Suspense>}/>,
      <Route path="coldex/:idcoldex/:idpoll/:idcategory" element={<Suspense fallback={<Loading/>}><PollsView/></Suspense>}/>
      <Route path="coldex/reporte/:idcoldex" element={<Loading/>} key="graficos"/></Route>,
    </Route>
  </Route>,
 
];
