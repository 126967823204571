import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IViewOfQuestionnaireToAnswerState,
  Signal,
} from "../../../utils/interfaces/store/IStore";
import { IPayloadSetError } from "../../Interfaces";
import { SimpleCompany } from "../../../utils/types/Company";
import { ColdexConfig } from "../../../utils/types/ColdexConfig";
import { RowDataColdex } from "../../../utils/types/RowDataColdex";
import { Coldex } from "../../../utils/types/Coldex";
import { UserAnswerxColdex } from "../../../utils/types/UserAnswerxColdex";
import { User } from "../../../utils/types/User";
import { ProfilePollType } from "../../../utils/types/ProfilePollType";
import { ProfilexColdex } from "../../../utils/types/ProfilexColdex";

const initialState: IViewOfQuestionnaireToAnswerState = {
  profiles: [],
  coldex: Signal.default(),
  companyTypes: Signal.default(),
  structure: Signal.default([]),
  advance: Signal.default(),
  companies: Signal.default([]),
  responseTypes: Signal.default([]),
  answers: Signal.default([]),
  permissions: Signal.default([]),
  answersSelected: [],
  focus: {
    total: 0,
    step: -1,
    dimension: -1,
    thematic: 0,
    question: 0,
  },
};

export const viewOfQuestionnaireToAnswerSlice = createSlice({
  name: "viewOfQuestionnaireToAnswer",
  initialState,
  reducers: {
    clean: (_) => {
      return {
        ...initialState,
      } as IViewOfQuestionnaireToAnswerState;
    },
    setInit: (state, action: PayloadAction<ProfilexColdex[]>) => {
      return {
        ...state,
        profiles: action.payload,
      } as IViewOfQuestionnaireToAnswerState;
    },
    getColdex: (state) => {
      return {
        ...state,
        coldex: Signal.loading(),
      } as IViewOfQuestionnaireToAnswerState;
    },
    getColdexSuccess: (state, action: PayloadAction<Coldex>) => {
      return {
        ...state,
        action: { ...action } as any,
        coldex: Signal.success(action.payload),
      } as IViewOfQuestionnaireToAnswerState;
    },
    getColdexError: (state, action: PayloadAction<IPayloadSetError>) => {
      return {
        ...state,
        action: { ...action } as any,
        coldex: Signal.error(action.payload.error, action.payload.shorterror),
      } as IViewOfQuestionnaireToAnswerState;
    },
    getCompanyTypes: (state) => {
      return {
        ...state,
        companyTypes: Signal.loading(),
      } as IViewOfQuestionnaireToAnswerState;
    },
    getCompanyTypesSuccess: (state, action: PayloadAction<string[]>) => {
      return {
        ...state,
        action: { ...action } as any,
        companyTypes: Signal.success(action.payload),
      } as IViewOfQuestionnaireToAnswerState;
    },
    getCompanyTypesError: (state, action: PayloadAction<IPayloadSetError>) => {
      return {
        ...state,
        action: { ...action } as any,
        companyTypes: Signal.error(
          action.payload.error,
          action.payload.shorterror
        ),
      } as IViewOfQuestionnaireToAnswerState;
    },
    getPermissions: (state) => {
      return {
        ...state,
        permissions: Signal.loading(),
      } as IViewOfQuestionnaireToAnswerState;
    },
    getPermissionsSuccess: (
      state,
      action: PayloadAction<ProfilePollType[]>
    ) => {
      const profiles = [...state.profiles];
      const result = action.payload.filter((p) =>
        profiles.some((pr) => pr.id === p.idProfile)
      );

      return {
        ...state,
        action: { ...action } as any,
        permissions: Signal.success(result),
      } as IViewOfQuestionnaireToAnswerState;
    },
    getPermissionsError: (state, action: PayloadAction<IPayloadSetError>) => {
      return {
        ...state,
        action: { ...action } as any,
        permissions: Signal.error(
          action.payload.error,
          action.payload.shorterror
        ),
      } as IViewOfQuestionnaireToAnswerState;
    },
    getStructure: (state) => {
      return {
        ...state,
        structure: Signal.loading(),
      } as IViewOfQuestionnaireToAnswerState;
    },
    getStructureSuccess: (state, action: PayloadAction<ColdexConfig[]>) => {
      let structure: ColdexConfig[] = [];
      const permissions: any = [...(state.permissions?.value ?? [])]?.reduce(
        (acc, p) => {
          const copy: any = { ...acc };
          if (!copy[p.pollType.acronym]) {
            copy[p.pollType.acronym] = [];
          }
          p.pollLevel.split("-").forEach((lvl) => {
            if (!copy[p.pollType.acronym].includes(Number(lvl))) {
              copy[p.pollType.acronym].push(Number(lvl));
            }
          });
          return copy;
        },
        {}
      );

      structure = [...action.payload]
        .map((d) => {
          let thematics = d.Children?.filter((t) =>
            permissions?.TTC?.includes(Number(t.PollLevel))
          );
          if (thematics?.length === 0) {
            thematics = permissions?.DNN?.includes(Number(d.PollLevel))
              ? d.Children
              : [];
          }

          return {
            ...d,
            Children: thematics,
          };
        })
        .filter(({ Children }) => Children?.length);

      return {
        ...state,
        action: { ...action } as any,
        structure: Signal.success(structure),
        focus: {
          ...state.focus,
          total: structure.reduce((acc, dimension) => {
            return (
              acc +
              (dimension.Children?.reduce(
                (childAcc, tematica) =>
                  childAcc + (tematica.Children?.length || 0),
                0
              ) || 0)
            );
          }, 0),
        },
      } as IViewOfQuestionnaireToAnswerState;
    },
    getStructureError: (state, action: PayloadAction<IPayloadSetError>) => {
      return {
        ...state,
        action: { ...action } as any,
        structure: Signal.error(
          action.payload.error,
          action.payload.shorterror
        ),
      } as IViewOfQuestionnaireToAnswerState;
    },
    getAdvance: (state) => {
      return {
        ...state,
        advance: Signal.loading(),
      } as IViewOfQuestionnaireToAnswerState;
    },
    getAdvanceSuccess: (state, action: PayloadAction<UserAnswerxColdex>) => {
      return {
        ...state,
        action: { ...action } as any,
        advance: Signal.success(action.payload),
      } as IViewOfQuestionnaireToAnswerState;
    },
    getAdvanceError: (state, action: PayloadAction<IPayloadSetError>) => {
      return {
        ...state,
        action: { ...action } as any,
        advance: Signal.error(action.payload.error, action.payload.shorterror),
      } as IViewOfQuestionnaireToAnswerState;
    },
    getCompanies: (state) => {
      return {
        ...state,
        companies: Signal.loading(),
      } as IViewOfQuestionnaireToAnswerState;
    },
    getCompaniesSuccess: (state, action: PayloadAction<SimpleCompany[]>) => {
      return {
        ...state,
        action: { ...action } as any,
        companies: Signal.success(action.payload),
      } as IViewOfQuestionnaireToAnswerState;
    },
    getCompaniesError: (state, action: PayloadAction<IPayloadSetError>) => {
      return {
        ...state,
        action: { ...action } as any,
        companies: Signal.error(
          action.payload.error,
          action.payload.shorterror
        ),
      } as IViewOfQuestionnaireToAnswerState;
    },
    getAnswers: (state) => {
      return {
        ...state,
        answers: Signal.loading(),
      } as IViewOfQuestionnaireToAnswerState;
    },
    getAnswersSuccess: (state, action: PayloadAction<RowDataColdex[]>) => {
      return {
        ...state,
        action: { ...action } as any,
        answers: Signal.success(action.payload),
      } as IViewOfQuestionnaireToAnswerState;
    },
    getAnswersError: (state, action: PayloadAction<IPayloadSetError>) => {
      return {
        ...state,
        action: { ...action } as any,
        answers: Signal.error(action.payload.error, action.payload.shorterror),
      } as IViewOfQuestionnaireToAnswerState;
    },
    onChange: (state, action: PayloadAction<any>) => {
      const company: SimpleCompany = action.payload.company;
      const statement: ColdexConfig = action.payload.statement;
      const user: User = action.payload.user;

      let answers = [...state.answersSelected];

      if (
        answers.find(
          (row) =>
            row.ndocTo === company.ndocCompany &&
            row.pollLevel4 === statement.PollLevel
        )
      ) {
        return {
          ...state,
          action: { ...action } as any,
        };
      }

      if (
        state.answersSelected.find((row) => row.ndocTo === company.ndocCompany)
      ) {
        answers = state.answersSelected.filter(
          (row) => row.ndocTo !== company.ndocCompany
        );
      }

      answers.push({
        idColdex: state.coldex.value!.id!,
        timestamp: new Date().toISOString(),
        email: user.email,
        tdocFrom: user.tdocCompany,
        ndocFrom: user.ndocCompany,
        tdocTo: company.tdocCompany,
        ndocTo: company.ndocCompany,
        idPoll: statement.IDPoll!,
        idCategory: statement.IDCategory!,
        idType: state.dimension?.IDType!,
        pollLevel1: state.dimension?.PollLevel,
        pollDesc1: state.dimension?.Value,
        pollLevel2: state.thematic?.PollLevel,
        pollDesc2: state.thematic?.Value,
        pollLevel3: state.question?.PollLevel,
        pollDesc3: state.question?.Value,
        pollLevel4: statement.PollLevel,
        pollDesc4: statement.Value,
        calification: statement?.Calification ? `${statement.Calification}` : null,
      } as RowDataColdex);

      return {
        ...state,
        action: { ...action } as any,
        answersSelected: answers,
      } as IViewOfQuestionnaireToAnswerState;
    },
    next: (state) => {
      const focus = { ...state.focus };

      if (focus.dimension === -1) {
        return {
          ...state,
          dimension: state.structure.value![0],
          thematic: state.structure.value![0].Children![0],
          question: state.structure.value![0].Children![0].Children![0],
          focus: { ...focus, step: 0, dimension: 0, thematic: 0, question: 0 },
        } as IViewOfQuestionnaireToAnswerState;
      }

      const config = state.structure.value!;

      const getMaxValues = (dimension: number, thematic: number) => ({
        maxDimensions: config.length,
        maxThematics: config[dimension]?.Children?.length || 0,
        maxQuestions:
          config[dimension]?.Children?.[thematic]?.Children?.length || 0,
      });

      let newFocus = { ...focus };

      const { maxDimensions, maxThematics, maxQuestions } = getMaxValues(
        focus.dimension,
        focus.thematic
      );

      if (focus.question < maxQuestions - 1) {
        newFocus.question++;
      } else if (focus.thematic < maxThematics - 1) {
        newFocus.thematic++;
        newFocus.question = 0;
      } else if (focus.dimension < maxDimensions - 1) {
        newFocus.dimension++;
        newFocus.thematic = 0;
        newFocus.question = 0;
      } else {
        return;
      }
      newFocus.step++;

      return {
        ...state,
        dimension: state.structure.value![newFocus.dimension],
        thematic:
          state.structure.value![newFocus.dimension].Children![
            newFocus.thematic
          ],
        question:
          state.structure.value![newFocus.dimension].Children![
            newFocus.thematic
          ].Children![newFocus.question],
        focus: newFocus,
      } as IViewOfQuestionnaireToAnswerState;
    },
    previous: (state) => {
      const focus = { ...state.focus };
      const config = state.structure.value!;

      const getMaxValues = (dimension: number, thematic: number) => ({
        maxDimensions: config.length,
        maxThematics: config[dimension]?.Children?.length || 0,
        maxQuestions:
          config[dimension]?.Children?.[thematic]?.Children?.length || 0,
      });

      let newFocus = { ...focus };

      if (focus.question > 0) {
        newFocus.question--;
      } else if (focus.thematic > 0) {
        newFocus.thematic--;
        newFocus.question =
          getMaxValues(focus.dimension, newFocus.thematic).maxQuestions - 1;
      } else if (focus.dimension > 0) {
        newFocus.dimension--;
        newFocus.thematic =
          getMaxValues(newFocus.dimension, 0).maxThematics - 1;
        newFocus.question =
          getMaxValues(newFocus.dimension, newFocus.thematic).maxQuestions - 1;
      } else {
        return {
          ...state,
          dimension: undefined,
          thematic: undefined,
          question: undefined,
          focus: {
            ...focus,
            step: -1,
            dimension: -1,
          },
        } as IViewOfQuestionnaireToAnswerState;
      }

      newFocus.step--;

      return {
        ...state,
        dimension: state.structure.value![newFocus.dimension],
        thematic:
          state.structure.value![newFocus.dimension].Children![
            newFocus.thematic
          ],
        question:
          state.structure.value![newFocus.dimension].Children![
            newFocus.thematic
          ].Children![newFocus.question],
        focus: newFocus,
      } as IViewOfQuestionnaireToAnswerState;
    },
    save: (state) => {
      return {
        ...state,
        answers: Signal.loading(state.answers.value),
      } as IViewOfQuestionnaireToAnswerState;
    },
    saveSuccess: (state, action: PayloadAction<RowDataColdex[]>) => {
      return {
        ...state,
        action: { ...action } as any,
        answers: Signal.success([...state.answers.value!, ...action.payload]),
        answersSelected: [],
      } as IViewOfQuestionnaireToAnswerState;
    },
    saveError: (state, action: PayloadAction<IPayloadSetError>) => {
      return {
        ...state,
        action: { ...action } as any,
        answers: Signal.error(action.payload.error, action.payload.shorterror),
      } as IViewOfQuestionnaireToAnswerState;
    },
    saveAdvance: (state) => {
      return {
        ...state,
        advance: Signal.loading(state.advance.value),
      } as IViewOfQuestionnaireToAnswerState;
    },
    saveAdvanceSuccess: (state, action: PayloadAction<UserAnswerxColdex>) => {
      return {
        ...state,
        action: { ...action } as any,
        advance: Signal.success(action.payload),
      } as IViewOfQuestionnaireToAnswerState;
    },
    saveAdvanceError: (state, action: PayloadAction<IPayloadSetError>) => {
      return {
        ...state,
        action: { ...action } as any,
        advance: Signal.error(action.payload.error, action.payload.shorterror),
      } as IViewOfQuestionnaireToAnswerState;
    },
  },
});

export default viewOfQuestionnaireToAnswerSlice;
