import { PendingActionsOutlined } from "@mui/icons-material";
import { SpinnerCircular } from "spinners-react";
import { useAppSelector } from "../../../store/Hooks";
import { RootState } from "../../../store/Store";
import {
  TITLE_COLDEX_SECTION_RED,
  TITLE_COLDEX_SECTION_SECTORIAL,
} from "../../../utils/constants/text-constants";
import useActionsUploadTemplate from "../../../utils/hooks/coldex/useActionsUploadTemplate";
import useColdexConfig from "../../../utils/hooks/coldex/useColdexConfig";
import { IPopUpColdex } from "../../../utils/interfaces/app/IPopUps";
import { PreviewColdexPopUp } from "../../PopUps/Preview/PreviewColdexPopUp";
import { Stadistics } from "./components/Stadistics";

/**  Este es un componente funcional encargado de mostrar y manejar los datos necesarios para subir un archivo */
export const UploadFileForm = ({
  isOpen,
  setIsOpen,
  coldex,
  type,
}: IPopUpColdex) => {
  const {language} = useAppSelector( (state:RootState) => state.multiLanguage);
  const {
    file,
    sectors,
    sectorxCompany,
    coldexConfig,
    permissions,
    participants,
    usersColdex,
    usersxColdex,
    padrinoxColdex,
    template,
    handleProcessFile,
  } = useActionsUploadTemplate(coldex!, type!);
  const {
    flagStadisticView,
    flagStatePreview,
    loadStadisticColdex,
    flagStateUploadTemplate,
    loadStadisticCompany,
    loadStadisticSector,
    previewP,
    setPreviewP,
    dataColdex,
    handleColdexCreateConfigPublic,
    handleColdexCreateConfigSectorial,
    handleColdexCreateConfigNetworkLeader,
    handlePreview,
    handleState,
  } = useColdexConfig(
    sectors,
    sectorxCompany,
    coldexConfig,
    usersColdex,
    usersxColdex,
    permissions,
    participants,
    padrinoxColdex,
    template,
    setIsOpen
  );
  return (
    <>
      <div className="popUp-Content__form">
        <div className="popUp-Content__form_field">
          <label className="popUp-Content__form_field-labelFile">
            <span>{language.components.forms.file.labels.LABEL_FOUND_FILE}</span>
            {file !== undefined ? file.name! : language.components.forms.file.descriptions.TEXT_INPUT_SELECT_FILE}
            <input
              type="file"
              onChange={(e) => {
                handleProcessFile(e);
              }}
            ></input>
          </label>
        </div>
        <div className="popUp-Content__form_field ">
          {flagStadisticView && (
            <>
              <Stadistics
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                loadStadisticCompany={loadStadisticCompany!}
                loadStadisticSector={loadStadisticSector!}
                loadStadisticColdex={loadStadisticColdex!}
                dataColdex={dataColdex!}
                usersColdex={usersxColdex}
                padrinoxColdex={padrinoxColdex}
              ></Stadistics>
            </>
          )}
        </div>
      </div>
      <div className="popUp-Content__actions">
        <button
          className="btn_OutLine"
          onClick={(e) => handleState(e)}
          disabled={loadStadisticColdex && true}
        >
          {language.components.forms.buttons.BTN_FORM_CANCEL}
        </button>
        {flagStateUploadTemplate && !flagStatePreview ? (
          <button onClick={() => handlePreview()}>Preview</button>
        ) : (
          <button
            className="btn-With-Svg"
            onClick={() => {
              type === TITLE_COLDEX_SECTION_SECTORIAL
                ? handleColdexCreateConfigSectorial()
                : type === TITLE_COLDEX_SECTION_RED ?
                  handleColdexCreateConfigNetworkLeader():
                  handleColdexCreateConfigPublic();
            }}
            disabled={flagStadisticView || file === undefined ? true : false}
          >
            {flagStadisticView ? (
              <>
                <SpinnerCircular size={24}></SpinnerCircular> &nbsp;
                {language.components.forms.file.buttons.BTN_LOAD_STATE}   
              </>
            ) : (
              <>
                <PendingActionsOutlined fontSize="small" />  {language.components.forms.file.buttons.BTN_PROCESS}   
              </>
            )}
          </button>
        )}
      </div>
      {previewP && (
        <PreviewColdexPopUp flag={previewP} coldex={coldex!} setIsOpen={setPreviewP}         
        ></PreviewColdexPopUp>
      )}
    </>
  );
};
