import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITempletexColdexState } from "../../../utils/interfaces/store/IStore";
import { IPayloadSetdata, IPayloadSetError } from "../../Interfaces";
import { TemplatexColdex } from "../../../utils/types/TemplatexColdex";

const initialState: ITempletexColdexState = {
  templateResults: null,
  shortMessage: "",
  resultMessage: "",
  dataError: false,
  open: false,
  isLoading: false,
};

export const templatexColdexSlice = createSlice({
  name: "TempletexColdex",
  initialState,
  reducers: {
    cleanTemplate: (state) => {
      return { ...initialState };
    },
    startLoadingTemplate: (state) => {
      state.isLoading = true;
    },
    setTemplatexColdex: (
      state,
      { payload }: PayloadAction<IPayloadSetdata<TemplatexColdex>>
    ) => {
      state.isLoading = false;
      state.dataError = false;
      state.templateResults = payload.resultObject;
      state.resultMessage = payload.resultMessage!;
    },
    setError: (state, { payload }: PayloadAction<IPayloadSetError>) => {
      state.isLoading = false;
      state.dataError = payload.iserror;
      state.resultMessage = payload.error;
      state.shortMessage = payload.shorterror;
      state.open = payload.open!;
    },
  },
});

export const { cleanTemplate, startLoadingTemplate, setTemplatexColdex, setError } =
  templatexColdexSlice.actions;

export default templatexColdexSlice.reducer;
