import { useEffect, useState } from "react";
import "./UserProfile.scss";
import { useAppDispatch, useAppSelector } from "../../store/Hooks";
import { RootState } from "../../store/Store";
import profileView from "../../store/slices/profileView/Thunks";
import { ProfilexColdex } from "../../utils/types/ProfilexColdex";
import { useNavigate, useParams } from "react-router";
import Button from "../../components/Button/Button";
import { PageTitle } from "../../components/Titles/PageTitle";
import { TITLE_COLDEX } from "../../utils/constants/text-constants";
import { ColdexUser } from "../../utils/types/Coldex";

export default function UserProfile() {
  const { idcoldex } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state: RootState) => state.loginuser);
  const {language} = useAppSelector( (state:RootState) => state.multiLanguage);
  const { userProfiles, profiles, selected, saved, idPolls, securitySaved } = useAppSelector(
    ({ profileView }: RootState) => profileView
  );
  const { coldex: coldexs } = useAppSelector(
    (state: RootState) => state.userxcoldex
  );

  const [coldexSelected, setcoldexSelected] = useState<ColdexUser>();

  useEffect(() => {
    dispatch(profileView.clean());
    dispatch(profileView.getUserProfiles(Number(idcoldex!), user.id!));
    dispatch(profileView.getProfiles(Number(idcoldex!)));

    coldexs.forEach((coldex: ColdexUser) => {
      if (coldex.idColdex === Number(idcoldex)) {
        setcoldexSelected(coldex);
      }
    });

    return () => {
      dispatch(profileView.clean());
    };
  }, []);

  useEffect(() => {
    if (saved?.length === selected.length) {
      dispatch(profileView.getUserProfiles(Number(idcoldex!), user.id!));
      saved?.forEach(({id}) => dispatch(profileView.getIdPolls(Number(idcoldex!), Number(id))))
    }
  }, [saved]);

  useEffect(() => {
    if ((idPolls?.length ?? 0) > 0 && idPolls?.length === saved?.length) {
      if (idPolls.flat().filter(id => id !== 'AUE').length === 0) {
        dispatch(profileView.saveSecurity(user.id!, Number(idcoldex)));
      } else {
        navigate(`/user/coldex/${idcoldex}/partnerToEvaluate`);
      }
    }
  }, [idPolls])

  useEffect(() => {
    if (securitySaved) {
      navigate(`/user/coldex/${idcoldex}`);
    }
  }, [securitySaved]);

  function handleSelect(profile: ProfilexColdex) {
    dispatch(profileView.select(profile));
  }

  function handleSave() {
    userProfiles
      ?.filter((up) => selected.find((s) => s.id === up.id) === undefined)
      ?.forEach((up) => dispatch(profileView.delete(Number(idcoldex!), user.id!, up)));

    selected.forEach((s) => dispatch(profileView.save(Number(idcoldex!), user.id!, s)));
  }

  return (
    <div className="container">
      {coldexSelected !== undefined && (
        <PageTitle
          title={` ${coldexSelected.coldexName} - ${coldexSelected.coldexYear}`}
          icon={TITLE_COLDEX}
          flag
        />
      )}
      <div className="Container">
        <h1 className="Title">{language.views.userProfile.header.TITLE_LABEL}</h1>

        <p className="Paragraph">
          {language.views.userProfile.header.DESCRIPTION_PARAGRAPH
            .replace("{length}", `${profiles.length}`)
            .replace("{profiles}", profiles.map(({ name }) => name).join(", "))}
        </p>

        <div className="Profiles">
          {profiles.map((p) => (
            <div
              id={`Profile${p.id}`}
              key={`Profile${p.id}`}
              className={`Profile${
                selected?.find((s) => s.id === p.id) !== undefined
                  ? " Selected"
                  : ""
              }`}
              onClick={() => handleSelect(p)}
            >
              <h3 className="ProfileTitle">{p.name}</h3>

              <p className="ProfileDescription">{p.description}</p>
            </div>
          ))}
        </div>

        <div className="Actions">
          <Button disabled={selected.length === 0} onClick={handleSave}>
            {language.views.userProfile.actions.CONTINUE_BUTTON}
          </Button>
        </div>
      </div>
    </div>
  );
}
