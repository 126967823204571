import { useEffect, useState } from "react";
import "./PartnerToEvaluateView.scss";
import { useAppDispatch, useAppSelector } from "../../store/Hooks";
import { RootState } from "../../store/Store";
import { useNavigate, useParams } from "react-router";
import Button from "../../components/Button/Button";
import partnerToEvaluateView from "../../store/slices/partnerToEvaluateView/Thunks";
import { Autocomplete, Chip, IconButton, TextField } from "@mui/material";
import { Category } from "../../utils/types/Categorys";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { PartnerEvaluated } from "../../utils/types/PartnerEvaluated";
import PartnerToEvaluatePopUp from "./PartnerToEvaluatePopUp";
import { PageTitle } from "../../components/Titles/PageTitle";
import { TITLE_COLDEX } from "../../utils/constants/text-constants";
import { ColdexUser } from "../../utils/types/Coldex";
import PartnerToEvaluatePreviousSelectionPopUp from "./PartnerToEvaluatePreviousSelectionPopUp";

export default function PartnerToEvaluateView() {
  const { idcoldex } = useParams();
  const coldexId = Number(idcoldex!);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    userPartners,
    categories,
    selectedCategories,
    companyTypes,
    selectedCompanyType,
    partners,
    selectedPartners,
    savedPartners,
    isOpen,
    securitySaved,
    lastEvaluatedCompanies
  } = useAppSelector(
    ({ partnerToEvaluateView }: RootState) => partnerToEvaluateView
  );
  const { user } = useAppSelector((state: RootState) => state.loginuser);
  const {language} = useAppSelector( (state:RootState) => state.multiLanguage);
  const { coldex: coldexs } = useAppSelector(
    (state: RootState) => state.userxcoldex
  );

  const [coldexSelected, setcoldexSelected] = useState<ColdexUser>();
  const [pagination, setPagination] = useState({
    page: 0,
    itemsPerPage: 10,
    total: 0,
  });

  useEffect(() => {
    dispatch(partnerToEvaluateView.clean());
    dispatch(partnerToEvaluateView.getUserPartners(coldexId, Number(user.id!)));
    dispatch(partnerToEvaluateView.getCategories(coldexId));
    dispatch(partnerToEvaluateView.getCompanyTypes(coldexId, Number(user.id!)));

    coldexs.forEach((coldex: ColdexUser) => {
      if (coldex.idColdex === Number(idcoldex)) {
        setcoldexSelected(coldex);
      }
    });
    return () => {
      dispatch(partnerToEvaluateView.clean());
    };
  }, []);

  useEffect(() => {
    if ((userPartners?.length ?? 0) > 0) {
      navigate(`/user/coldex/${idcoldex}`);
    }
  }, [userPartners])

  useEffect(() => {
    if (companyTypes.length === 1) {
      dispatch(partnerToEvaluateView.selectCompanyType(companyTypes[0]));
    }
    if (companyTypes.length > 1) {
      dispatch(partnerToEvaluateView.getPartners(coldexId));
    }
  }, [companyTypes]);

  useEffect(() => {
    dispatch(
      partnerToEvaluateView.getPartners(
        coldexId,
        selectedCompanyType ?? undefined,
        selectedCategories.length > 0
          ? selectedCategories.map(({ code }) => code)
          : undefined
      )
    );
  }, [selectedCategories, selectedCompanyType]);

  useEffect(() => {
    setPagination({ ...pagination, total: partners.length });
  }, [partners]);

  useEffect(() => {
    if (
      !isOpen &&
      savedPartners &&
      selectedPartners.length === savedPartners.length
    ) {
      dispatch(partnerToEvaluateView.saveSecurity(user.id!, Number(idcoldex)));
    }
  }, [isOpen, savedPartners]);

  useEffect(() => {
    if (securitySaved) {
      navigate(`/user/coldex/${idcoldex}`);
    }
  }, [securitySaved]);

  function handleSelect(partner: PartnerEvaluated) {
    dispatch(partnerToEvaluateView.select(partner));
  }

  function handleReturn() {
    navigate(`/user/coldex/${idcoldex}/profile`);
  }

  return (
    <div className="container">
    {coldexSelected !== undefined && (
      <PageTitle
        title={` ${coldexSelected.coldexName} - ${coldexSelected.coldexYear}`}
        icon={TITLE_COLDEX}
        flag
      />
    )}
      <div className="Container">
        <h1 className="Title">{language.views.partnerToEvaluate.header.TITLE_LABEL}</h1>

        <p className="Paragraph">{language.views.partnerToEvaluate.content.DESCRIPTION_LABEL}</p>

        <div className="FilterSection">
          <Autocomplete
            id="companyType"
            value={selectedCompanyType}
            onChange={(event, newValue) => {
              dispatch(partnerToEvaluateView.selectCompanyType(newValue ?? undefined));
            }}
            options={companyTypes}
            getOptionLabel={(option: string) => option}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index });
                return <Chip key={key} label={option} {...tagProps} />;
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={language.views.partnerToEvaluate.actions.AUTOCOMPLETE_CATEGORIES_LABEL}
                placeholder={language.views.partnerToEvaluate.actions.AUTOCOMPLETE_CATEGORIES_PLACEHOLDER}
              />
            )}
            className="Autocomplete"
          />

          <Autocomplete
            multiple
            id="category"
            value={selectedCategories}
            onChange={(event, newValue) => {
              dispatch(partnerToEvaluateView.selectCategory(newValue));
            }}
            options={categories}
            getOptionLabel={(option: Category) => option.description}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <Chip key={key} label={option.description} {...tagProps} />
                );
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={language.views.partnerToEvaluate.actions.AUTOCOMPLETE_CATEGORIES_COMPANY_TYPE_LABEL}
                placeholder={language.views.partnerToEvaluate.actions.AUTOCOMPLETE_CATEGORIES_COMPANY_TYPE_PLACEHOLDER}
              />
            )}
            className="Autocomplete"
          />
        </div>

        <div className="ContentSection">
          {pagination.page > 0 && (
            <IconButton
              sx={{ color: "#00b398" }}
              aria-label="add to shopping cart"
              onClick={() =>
                setPagination({ ...pagination, page: pagination.page - 1 })
              }
            >
              <KeyboardDoubleArrowLeftIcon sx={{ fontSize: 90 }} />
            </IconButton>
          )}

          <div className="Partners">
            {partners
              .slice(
                pagination.page * pagination.itemsPerPage,
                pagination.page * pagination.itemsPerPage +
                  pagination.itemsPerPage
              )
              .map((p) => (
                <div
                  id={`Partner${p.code}-${p.ndoc}`}
                  key={`Partner${p.code}-${p.ndoc}`}
                  className={`Partner`}
                >
                  {selectedPartners?.find(
                    (s) => s.code === p.code && s.ndoc === p.ndoc
                  ) !== undefined ? (
                    <RadioButtonCheckedIcon
                      className="IconSelected"
                      sx={{ color: "#00b398" }}
                      onClick={() => handleSelect(p)}
                    />
                  ) : (
                    <RadioButtonUncheckedIcon
                      className="Icon"
                      sx={{ color: "#00b398" }}
                      onClick={() => handleSelect(p)}
                    />
                  )}

                  <img
                    src={
                      process.env.REACT_APP_URL_BASE + `${p.tdoc}-${p.ndoc}.png`
                    }
                    alt={`${p.tdoc}-${p.ndoc}`}
                    className="Image"
                  />
                  <p className="PartnerTitle">
                    {p.description} - {p.companyName}
                  </p>
                </div>
              ))}
          </div>

          {pagination.page <
            Math.ceil(pagination.total / pagination.itemsPerPage) - 1 && (
            <IconButton
              sx={{ color: "#00b398" }}
              aria-label="add to shopping cart"
              onClick={() =>
                setPagination({ ...pagination, page: pagination.page + 1 })
              }
            >
              <KeyboardDoubleArrowRightIcon sx={{ fontSize: 90 }} />
            </IconButton>
          )}
        </div>

        <div>
          <p className="Pagination">
            {pagination.page * pagination.itemsPerPage + 1} -{" "}
            {Math.min(
              (pagination.page + 1) * pagination.itemsPerPage,
              pagination.total
            )}
            {" "}
            {language.views.partnerToEvaluate.actions.OF_LABEL}
            {" "}
            {pagination.total}
          </p>
        </div>

        <div className="ActionsSection">
          <Button btnType="outline" onClick={handleReturn}>
            {language.views.partnerToEvaluate.actions.BTN_RETURN_LABEL}
          </Button>
          
          <PartnerToEvaluatePreviousSelectionPopUp/>
          <PartnerToEvaluatePopUp />
        </div>
      </div>
    </div>
  );
}
