import { Action, Dispatch } from "@reduxjs/toolkit";
import { ColdexAPI } from "../../../apis/apiColdex/ColdexAPI";
import { IApiResult } from "../../../utils/interfaces/store/IApiResponse";
import profileViewSlice from "./profileView";
import { ProfilexColdex } from "../../../utils/types/ProfilexColdex";

const profileView = {
  clean: () => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(profileViewSlice.actions.clean());
    };
  },
  select: (profile: ProfilexColdex) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(profileViewSlice.actions.select(profile));
    };
  },
  getUserProfiles: (coldexId: number, userId: number) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(profileViewSlice.actions.getUserProfiles());

      ColdexAPI.get<IApiResult<ProfilexColdex[]>>(
        `adminColdex/coldex/${coldexId}/user/${userId}/profile`
      )
        .then(({ data }) => {
          if (data.dataError) {
            dispatch(
              profileViewSlice.actions.getUserProfilesError({
                iserror: true,
                open: true,
                shorterror: `Error short`,
                error: `Error`,
              })
            );
            return;
          }

          dispatch(
            profileViewSlice.actions.getUserProfilesSuccess({
              resultObject: data.resultObject,
            })
          );
        })
        .catch((error) => {
          dispatch(
            profileViewSlice.actions.getUserProfilesError({
              iserror: true,
              open: true,
              shorterror: `Error short`,
              error: `Error`,
            })
          );
        });
    };
  },
  getProfiles: (idColdex: number) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(profileViewSlice.actions.getProfiles());

      ColdexAPI.get<IApiResult<ProfilexColdex[]>>(
        `adminColdex/coldex/${idColdex}/profile`
      )
        .then(({ data }) => {
          if (data.dataError) {
            dispatch(
              profileViewSlice.actions.getProfilesError({
                iserror: true,
                open: true,
                shorterror: `Error short`,
                error: `Error`,
              })
            );
            return;
          }

          dispatch(
            profileViewSlice.actions.getProfilesSuccess({
              resultObject: data.resultObject,
            })
          );
        })
        .catch((error) => {
          dispatch(
            profileViewSlice.actions.getProfilesError({
              iserror: true,
              open: true,
              shorterror: `Error short`,
              error: `Error`,
            })
          );
        });
    };
  },
  delete: (coldexId: number, userId: number, profile: ProfilexColdex) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(profileViewSlice.actions.delete());

      ColdexAPI.delete<IApiResult<void>>(
        `adminColdex/coldex/${coldexId}/user/${userId}/profile/${profile.id}`
      )
        .then(({ data }) => {
          if (data.dataError) {
            dispatch(
              profileViewSlice.actions.deleteError({
                iserror: true,
                open: true,
                shorterror: `Error short`,
                error: `Error`,
              })
            );
            return;
          }

          dispatch(
            profileViewSlice.actions.deleteSuccess({
              resultObject: profile,
            })
          );
        })
        .catch((error) => {
          dispatch(
            profileViewSlice.actions.deleteError({
              iserror: true,
              open: true,
              shorterror: `Error short`,
              error: `Error`,
            })
          );
        });
    };
  },
  save: (coldexId: number, userId: number, profile: ProfilexColdex) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(profileViewSlice.actions.save());

      ColdexAPI.post<IApiResult<void>>(
        `adminColdex/coldex/${coldexId}/user/${userId}/profile/${profile.id}`
      )
        .then(({ data }) => {
          if (data.dataError) {
            dispatch(
              profileViewSlice.actions.saveError({
                iserror: true,
                open: true,
                shorterror: `Error short`,
                error: `Error`,
              })
            );
            return;
          }

          dispatch(
            profileViewSlice.actions.saveSuccess({
              resultObject: profile,
            })
          );
        })
        .catch((error) => {
          dispatch(
            profileViewSlice.actions.saveError({
              iserror: true,
              open: true,
              shorterror: `Error short`,
              error: `Error`,
            })
          );
        });
    };
  },
  getIdPolls: (coldexId: number, idProfile: number) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(profileViewSlice.actions.getIdPolls());

      ColdexAPI.get<IApiResult<string[]>>(
        `adminColdex/role/${idProfile}/pollType/getIdPoll?idColdex=${coldexId}`
      )
        .then(({ data }) => {
          if (data.dataError) {
            dispatch(
              profileViewSlice.actions.getIdPollsError({
                iserror: true,
                open: true,
                shorterror: `Error short`,
                error: `Error`,
              })
            );
            return;
          }

          dispatch(
            profileViewSlice.actions.getIdPollsSuccess({
              resultObject: data.resultObject,
            })
          );
        })
        .catch((error) => {
          dispatch(
            profileViewSlice.actions.getIdPollsError({
              iserror: true,
              open: true,
              shorterror: `Error short`,
              error: `Error`,
            })
          );
        });
    };
  },
  saveSecurity: (userId: number, coldexId: number) => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(profileViewSlice.actions.saveSecurity());

      ColdexAPI.post<IApiResult<void>>(`adminColdex/SecurityxUserxColdex/${coldexId}?IdUser=${userId}`)
        .then(({ data }) => {
          if (data.dataError) {
            dispatch(
              profileViewSlice.actions.saveSecurityError({
                iserror: true,
                open: true,
                shorterror: `Error short`,
                error: `Error`,
              })
            );
            return;
          }

          dispatch(profileViewSlice.actions.saveSecuritySuccess());
        })
        .catch((error) => {
          dispatch(
            profileViewSlice.actions.saveSecurityError({
              iserror: true,
              open: true,
              shorterror: `Error short`,
              error: `Error`,
            })
          );
        });
    };
  },
};

export default profileView;
