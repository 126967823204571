import {
  AnyAction,
  combineReducers,
  configureStore,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import adminUsersReducer from "./slices/adminUsers/adminUsersSlice";
import loginReducer from "./slices/loginUser/loginUserSlice";
import companyReducer from "./slices/company/companySlice";
import coldexReducer from "./slices/coldex/coldexSlice";
import parametersReducer from "./slices/parameters/parametersSlice";
import templeteFileReducer from "./slices/templeteFile/templeteSlice";
import categoryxColdexReducer from "./slices/categoryXColdex/categoryXColdex";
import templatexColdexReducer from "./slices/templatexColdex/templatexColdexSlice";
import companyxIndxColdexReducer from "./slices/companyXIndXColdex/companyXIndXColdex";
import pollxColdexReducer from "./slices/pollxColdex/pollxColdexConfig";
import userReducer from "./slices/userxColdex/userxColdexSlice";
import userxsecurityReducer from "./slices/securityxUserxColdex/securityxUserxColdexSlice";
import PermissionsXRolReducer from "./slices/permissionXRol/permissionXRol";
import pollTypeReducer from "./slices/pollTypes/pollTypesSlice";
import sendMailReducer from "./slices/sendMail/sendMailSlice";
import padrinoxColdexReducer from "./slices/padrinoxColdex/padrinoxColdexSlice";
import userAnswerXColdexReducer from "./slices/userAnswerxColdex/userAnswerxColdexSlice";
import rowDataReducer from "./slices/rowData/rowDataColdexSlice";
import rowDataLRReducer from "./slices/rowDataLR/rowDataColdexLRSlice";
import rolReducer from "./slices/role/rolSlice";
import reportReducer from "./slices/reports/reportSlice";
import monitoringReportReducer from "./slices/monitoringReports/monitoringReportSlice";
import storage from "redux-persist/lib/storage";
import sectionReducer from "./slices/section/sectionSlice";
import sectionResourceReducer from "./slices/sectionResource/sectionResourceSlice";
import sectionFormReducer from "./slices/sectionForm/sectionFormSlice";
import sectionFormResponseReducer from './slices/formUserResponse/formUserResponseSlice'
import { profileViewSlice } from "./slices/profileView";
import multiLanguageReducer from "./slices/multiLanguage/multiLanguageSlice";
import companyColdexCategoryReducer from "./slices/companyColdexCategory/companyColdexCategory";
import { persistReducer, persistStore } from "redux-persist";
import { partnerToEvaluateViewSlice } from "./slices/partnerToEvaluateView";
import { viewOfQuestionnaireToAnswerSlice } from "./slices/viewOfQuestionnaireToAnswer";
import viewOfQuestionnaireToAnswerPASlice from "./slices/viewOfQuestionnaireToAnswerPA/viewOfQuestionnaireToAnswerPA";
import { viewOfQuestionnaireToAnswerAUESlice } from "./slices/viewOfQuestionnaireToAnswerAUE";

const persistConfig = {
  key: "root",
  storage,
};

const appReducer  = combineReducers({
  company: companyReducer,
  adminuser: adminUsersReducer,
  loginuser: loginReducer,
  coldex: coldexReducer,
  parameters: parametersReducer,
  templeteFile: templeteFileReducer,
  categoryxColdex: categoryxColdexReducer,
  templatexColdex: templatexColdexReducer,
  companyxIndxColdex: companyxIndxColdexReducer,
  pollxColdex: pollxColdexReducer,
  rol: rolReducer,
  userxcoldex: userReducer,
  userxsecurityxcoldex: userxsecurityReducer,
  permission: PermissionsXRolReducer,
  polltypes: pollTypeReducer,
  sendMail: sendMailReducer,
  padrinos: padrinoxColdexReducer,
  useranswerxcoldex: userAnswerXColdexReducer,
  rowData: rowDataReducer,
  rowDataLR: rowDataLRReducer,
  reporte: reportReducer,
  monitoringReport: monitoringReportReducer,
  section: sectionReducer,
  sectionResource: sectionResourceReducer,
  sectionForm: sectionFormReducer,
  sectionFormResponseUser : sectionFormResponseReducer,
  multiLanguage: multiLanguageReducer,
  companyColdexCategory:companyColdexCategoryReducer,
  profileView: profileViewSlice.reducer,
  [partnerToEvaluateViewSlice.name]: partnerToEvaluateViewSlice.reducer,
  [viewOfQuestionnaireToAnswerSlice.name]: viewOfQuestionnaireToAnswerSlice.reducer,
  [viewOfQuestionnaireToAnswerPASlice.name]: viewOfQuestionnaireToAnswerPASlice.reducer,
  [viewOfQuestionnaireToAnswerAUESlice.name]: viewOfQuestionnaireToAnswerAUESlice.reducer,
});
const reducerProxy = (state: any, action: AnyAction) => {
  if(action.type === 'logout/LOGOUT') {
    localStorage.clear();
 //   const url = new URL("https://app-clientappnew-dev.azurewebsites.net/login");
   // url.searchParams.set('reloadTime', Date.now().toString());
   // window.location.href = url.toString();
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
}
const persistedReducer = persistReducer(persistConfig, reducerProxy );


export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const logout = createAsyncThunk(
  "auth/logout",
  async function (_payload, thunkAPI) {
      thunkAPI.dispatch({ type: 'logout/LOGOUT' });
  }
);