import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import "../../components/PopUps/popUps.scss";
import { PopUpTitle } from "../../components/Titles/PopUpTitle";
import { SpinnerCircular } from "spinners-react";
import { useAppDispatch, useAppSelector } from "../../store/Hooks";
import { RootState } from "../../store/Store";

import partnerToEvaluateView from "../../store/slices/partnerToEvaluateView/Thunks";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";

export default function PartnerToEvaluatePreviousSelectionPopUp() {
  const { idcoldex } = useParams();
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [called, setCalled] = useState(false);
  const { lastEvaluatedCompanies, partners, savedPartners, isLoading } = useAppSelector(
    ({ partnerToEvaluateView }: RootState) => partnerToEvaluateView
  );
  const { user } = useAppSelector((state: RootState) => state.loginuser);
  const {language} = useAppSelector( (state:RootState) => state.multiLanguage);

  useEffect(() => {
    if (
      !isOpen &&
      lastEvaluatedCompanies.length  > 0
    ) {
      setIsOpen(true);
    }
  }, [lastEvaluatedCompanies,savedPartners]);

  useEffect(() => {
    if (!called && partners.length > 0) {
      dispatch(partnerToEvaluateView.getLastEvaluatedCompanies(Number(user.id!), Number(idcoldex!)));
      setCalled(!called)
    }
  }, [partners]);

  function handleSwitchOpenModal() {
    dispatch(partnerToEvaluateView.selectMany([]));
    setIsOpen(false)
  }


  function handleSave() {
    dispatch(partnerToEvaluateView.selectMany(lastEvaluatedCompanies));
    setIsOpen(false);
  }

  return (
    <>


      {isOpen && (
        <div className={isOpen ? "open-PopUp" : "close-PopUp"}>
          <div
            className="popUp-Content"
            style={{
              maxWidth: "700px",
              maxHeight: "70%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <PopUpTitle
              setIsOpen={handleSwitchOpenModal}
              title={language.components.popUps.previousSelecction.header.TITLE_LABEL}
            />
              <p>{language.components.popUps.previousSelecction.content.PARAGRAPH_LABEL}</p> 

              {lastEvaluatedCompanies.length > 0 && (
              <TableContainer style={{ maxWidth: "100%", overflowY: "auto" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        key={language.components.popUps.previousSelecction.content.TABLE_CHECK}
                        style={{ width: "25px" }}
                      ></TableCell>
                      <TableCell
                        key={language.components.popUps.previousSelecction.content.TABLE_CATEGORY}
                      >
                        {language.components.popUps.previousSelecction.content.TABLE_CATEGORY}
                      </TableCell>
                      <TableCell
                        key={language.components.popUps.previousSelecction.content.TABLE_PARTNER}
                      >
                        {language.components.popUps.previousSelecction.content.TABLE_PARTNER}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lastEvaluatedCompanies.map((s, i) => (
                      <TableRow key={`${s.code}-${s.ndoc}-Row`}>
                        <TableCell
                          key={`${s.code}-${s.ndoc}-Cell`}
                          style={{ width: "25px" }}
                        >
                        </TableCell>
                        <TableCell key={`description-${i}`}>
                          {s?.description ?? s.code}
                        </TableCell>
                        <TableCell key={`companyName-${i}`}>
                          {s.name}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            <div className="popUp-Content__actions">
              <button
                className="btn_OutLine"
                onClick={handleSwitchOpenModal}
                disabled={isLoading}
              >
                {language.components.popUps.previousSelecction.actions.BTN_FORM_CANCEL}
              </button>

              <button
                onClick={handleSave}
                disabled={isLoading}
                style={{ gap: "10px" }}
              >
                {isLoading && <SpinnerCircular size={24} />}

                {isLoading
                  ? language.components.popUps.previousSelecction.actions.BTN_FORM_LOADING
                  : language.components.popUps.previousSelecction.actions.BTN_FORM_SAVE}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
