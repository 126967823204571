import { ID_ROL_PADRINO, ID_ROL_USERCOLDEX, TITLE_COLDEX_SECTION_PUBLICO, TITLE_COLDEX_SECTION_RED, TITLE_COLDEX_SECTION_SECTORIAL } from "../../constants/text-constants";
import { IPermissionColdex } from "../../interfaces/app/IPopUps";
import { Coldex } from "../../types/Coldex";
import { ColdexConfig } from "../../types/ColdexConfig";
import { Padrino } from "../../types/Padrino";
import { Sector } from "../../types/Sector";
import { SectorxCompany } from "../../types/SectorxCompany";
import { User } from "../../types/User";
import { UserColdex } from "../../types/UserColdex";
import * as XLSX from "xlsx";
import React, { useState } from "react";
import { ICONFIG_GLOBAL_COLDEX, ISet_ColdexConfig, ISet_Padrinos, ISet_Sectors, ISet_SectorxCompany, ISet_Users, Padrinos, Sectores, SectorxCompañia, Usuarios,ColdexConfig as ColdexConfigColdex,Permissions as PermissionsColdex, ISet_Profiles, ISet_PollTypes, ISet_ProfilePollTypes, Profiles, PollTypes, ProfilePollTypes, ISet_ResponseTypes, ResponseTypes } from "../../types/UploadTemplateColdex";
import { ProfilePollType } from "../../types/ProfilePollType";
import { TemplatexColdex } from "../../types/TemplatexColdex";
import { ProfilexColdex } from "../../types/ProfilexColdex";
import { PollTypexColdex } from "../../types/PollTypexColdex";
import { ResponseType } from "../../types/ResponseType";

/**
 * Funcion que procesa una plantilla de coldex, para subir sus datos
 * @param coldex coldex del que se va a subir la plantilla
 * @param type tipo de coldex
 * @returns retorna un json con los siguien objetos y funciones
 * - file:
 * - sectors:array de Sectores Sectores/Categorias/Areas
 * - sectorxCompany: selaciona sectors con empresas
 * - coldexConfig: array con las dimensiones, areas/sectores, tipos de cuestionarios, descripciones, valores de calificacion, enunciados o preguntas, tematicas. 
 * - permissions: array con la informacion de que usuario responde que y a que empresa evalua
 * - participants:array de Sectores/Categorias/Areas
 * - usersColdex: array de usuarios
 * - usersxColdex: array de relaciones entre usuarios y coldex
 * - padrinoxColdex: array de padrinos del coldex
 * - handleProcessFile:Funcion que procesa el template
 */
const useActionsUploadTemplate = (coldex: Coldex, type: String) => {
        const [file, setFile] = useState<Blob | Object | any>();
        const [sectors, setSectors] = useState<Array<Sector>>([]);
        const [sectorxCompany, setSectorxCompany] = useState<Array<SectorxCompany>>([]);
        const [coldexConfig, setColdexConfig] = useState<Array<ColdexConfig>>([]);
        const [permissions, setPermissions] = useState<Array<IPermissionColdex>>([]);
        const [participants, setParticipants] = useState< Array<Sector>>([]);
        const [usersColdex, setUsersColdex] = useState<Array<User>>([]);
        const [usersxColdex, setUsersxColdex] = useState<Array<UserColdex>>([]);
        const [padrinoxColdex, setPadrinoxColdex] = useState<Array<Padrino>>([]);
        const [template, setTemplate] = useState<TemplatexColdex>({
          idColdex: 0,
          profiles: [],
          pollTypes: [],
          profilePollTypes: [],
          responseTypes: [],
        });

        const handleProcessFile = (e: React.ChangeEvent<any>) => {
        let CONFIG_GLOBAL_COLDEX: ICONFIG_GLOBAL_COLDEX|undefined = undefined;
        const promise = new Promise<ICONFIG_GLOBAL_COLDEX>((resolve, reject) => {
          const fileReader: FileReader = new FileReader();
          setFile(e.target.files[0]);
          fileReader.readAsArrayBuffer(e.target.files[0]);
          fileReader.onload = (e: ProgressEvent<FileReader>)=> {
            const bufferArray = e.target!.result;
            const wb = XLSX.read(bufferArray, { type: "buffer" });
            /**NOMBRE DE HOJAS */
            if (type ===  TITLE_COLDEX_SECTION_SECTORIAL) {
              const S_sectors: string = wb.SheetNames[0];
              const S_sectorxCompany: string = wb.SheetNames[1];
              const S_coldexConfig: string = wb.SheetNames[2];
              const S_Users = wb.SheetNames[3];
              const S_Padrinos = wb.SheetNames[4];
              const S_Profiles = wb.SheetNames[5];
              const S_PollTypes = wb.SheetNames[6];
              const S_ProfilePollTypes = wb.SheetNames[7];
              const S_ResponseTypes = wb.SheetNames[8];
              const RS_Sectors: XLSX.WorkSheet = wb.Sheets[S_sectors];
              const RS_sectorxCompany: XLSX.WorkSheet = wb.Sheets[S_sectorxCompany];
              const RS_coldexConfig: XLSX.WorkSheet = wb.Sheets[S_coldexConfig];
              const RS_users: XLSX.WorkSheet = wb.Sheets[S_Users];
              const RS_Padrinos: XLSX.WorkSheet = wb.Sheets[S_Padrinos];
              const RS_Profiles: XLSX.WorkSheet = wb.Sheets[S_Profiles];
              const RS_PollTypes: XLSX.WorkSheet = wb.Sheets[S_PollTypes];
              const RS_ProfilePollTypes: XLSX.WorkSheet = wb.Sheets[S_ProfilePollTypes];
              const RS_ResponseTypes: XLSX.WorkSheet = wb.Sheets[S_ResponseTypes];
              const Set_Sectors:ISet_Sectors  = {
                Sectores: XLSX.utils.sheet_to_json(RS_Sectors),
              };
              const Set_SectorxCompany: ISet_SectorxCompany  = {
                SectorxCompañia: XLSX.utils.sheet_to_json(RS_sectorxCompany),
              };
              const Set_ColdexConfig: ISet_ColdexConfig  = {
                ColdexConfig: XLSX.utils.sheet_to_json(RS_coldexConfig),
              };
              const Set_Users: ISet_Users = {
                Usuarios: XLSX.utils.sheet_to_json(RS_users),
              };
              const Set_Padrinos: ISet_Padrinos = {
                Padrinos: XLSX.utils.sheet_to_json(RS_Padrinos),
              };
              const Set_Roles: ISet_Profiles = {
                Profiles: XLSX.utils.sheet_to_json(RS_Profiles),
              };
              const Set_PollTypes: ISet_PollTypes = {
                PollTypes: XLSX.utils.sheet_to_json(RS_PollTypes),
              };
              const Set_ProfilePollTypes: ISet_ProfilePollTypes = {
                ProfilePollTypes: XLSX.utils.sheet_to_json(RS_ProfilePollTypes),
              };
              const Set_ResponseTypes: ISet_ResponseTypes = {
                ResponseTypes: XLSX.utils.sheet_to_json(RS_ResponseTypes),
              };

              CONFIG_GLOBAL_COLDEX = {
                Sectors:Set_Sectors,
                SectorxCompany:Set_SectorxCompany,
                ColdexConfig:Set_ColdexConfig,
                Users:Set_Users,
                Padrinos:Set_Padrinos,
                Profiles: Set_Roles,
                PollTypes: Set_PollTypes,
                ProfilePollTypes: Set_ProfilePollTypes,
                ResponseTypes: Set_ResponseTypes,
              } as ICONFIG_GLOBAL_COLDEX
              resolve(CONFIG_GLOBAL_COLDEX);
            } else if (type === TITLE_COLDEX_SECTION_RED) {
              const S_Categories = wb.SheetNames[0];
              const S_CategoryxCompany = wb.SheetNames[1];
              const S_ColdexConfig = wb.SheetNames[2];
              const S_Users = wb.SheetNames[3];
              const S_Padrinos = wb.SheetNames[4];
              const S_Profiles = wb.SheetNames[5];
              const S_PollTypes = wb.SheetNames[6];
              const S_ProfilePollTypes = wb.SheetNames[7];
              const S_ResponseTypes = wb.SheetNames[8];
              const RS_Categories: XLSX.WorkSheet = wb.Sheets[S_Categories];
              const RS_CategoryxCompany: XLSX.WorkSheet = wb.Sheets[S_CategoryxCompany];
              const RS_coldexConfig: XLSX.WorkSheet = wb.Sheets[S_ColdexConfig];
              const RS_users: XLSX.WorkSheet = wb.Sheets[S_Users];
              const RS_Padrinos: XLSX.WorkSheet = wb.Sheets[S_Padrinos];
              const RS_Profiles: XLSX.WorkSheet = wb.Sheets[S_Profiles];
              const RS_PollTypes: XLSX.WorkSheet = wb.Sheets[S_PollTypes];
              const RS_ProfilePollTypes: XLSX.WorkSheet = wb.Sheets[S_ProfilePollTypes];
              const RS_ResponseTypes: XLSX.WorkSheet = wb.Sheets[S_ResponseTypes];
              const Set_Participants: ISet_Sectors = {
                Sectores: XLSX.utils.sheet_to_json(RS_Categories),
              };
              const Set_SectorxCompany: ISet_SectorxCompany  = {
                SectorxCompañia: XLSX.utils.sheet_to_json(RS_CategoryxCompany),
              };
              const Set_ColdexConfig:ISet_ColdexConfig = {
                ColdexConfig: XLSX.utils.sheet_to_json(RS_coldexConfig),
              };
              const Set_Users: ISet_Users = {
                Usuarios: XLSX.utils.sheet_to_json(RS_users),
              };
              const Set_Padrinos: ISet_Padrinos= {
                Padrinos: XLSX.utils.sheet_to_json(RS_Padrinos),
              };
              const Set_Profiles: ISet_Profiles = {
                Profiles: XLSX.utils.sheet_to_json(RS_Profiles),
              };
              const Set_PollTypes: ISet_PollTypes = {
                PollTypes: XLSX.utils.sheet_to_json(RS_PollTypes),
              };
              const Set_ProfilePollTypes: ISet_ProfilePollTypes = {
                ProfilePollTypes: XLSX.utils.sheet_to_json(RS_ProfilePollTypes),
              };
              const Set_ResponseTypes: ISet_ResponseTypes = {
                ResponseTypes: XLSX.utils.sheet_to_json(RS_ResponseTypes),
              };
              CONFIG_GLOBAL_COLDEX = {
                Sectors:Set_Participants,
                SectorxCompany:Set_SectorxCompany,
                ColdexConfig:Set_ColdexConfig,
                Users:Set_Users,
                Padrinos:Set_Padrinos,
                Profiles: Set_Profiles,
                PollTypes: Set_PollTypes,
                ProfilePollTypes: Set_ProfilePollTypes,
                ResponseTypes: Set_ResponseTypes,
              } as ICONFIG_GLOBAL_COLDEX
              
              resolve(CONFIG_GLOBAL_COLDEX);
            }else if (type === TITLE_COLDEX_SECTION_PUBLICO) {
              const S_Participants = wb.SheetNames[0];
              const S_ColdexConfig = wb.SheetNames[1];
              const S_Users = wb.SheetNames[2];
              const RS_Participants: XLSX.WorkSheet = wb.Sheets[S_Participants];
              const RS_coldexConfig: XLSX.WorkSheet = wb.Sheets[S_ColdexConfig];
              const Set_Participants: ISet_Sectors = {
                Sectores: XLSX.utils.sheet_to_json(RS_Participants),
              };
             
              const Set_ColdexConfig:ISet_ColdexConfig = {
                ColdexConfig: XLSX.utils.sheet_to_json(RS_coldexConfig),
              };
          
              CONFIG_GLOBAL_COLDEX = {
                Sectors:Set_Participants,
                ColdexConfig:Set_ColdexConfig,
            }
              
              resolve(CONFIG_GLOBAL_COLDEX);
            }
          };
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
        promise.then((d: ICONFIG_GLOBAL_COLDEX) => {
          const users: Array<User> = [];
          const usersxColdex: Array<UserColdex> = [];
          let padrinosuser : Array<User>=[];
          const padrinos : Array<Padrino>=[];
          const template: TemplatexColdex = {
            idColdex: coldex.id!,
            profiles: [],
            pollTypes: [],
            profilePollTypes: [],
            responseTypes: [],
          };
          if (type ===  TITLE_COLDEX_SECTION_SECTORIAL) {
            let sectorAux: Array<Sector> = [];
            let sectorxCAux: Array<SectorxCompany> = [];
            let coldexConfigAux: Array<ColdexConfig> = [];
            d.Sectors.Sectores.map((sect:Sectores) => {
              let aux: Sector = {
                IDColdex: coldex.id!,
                code: sect.ID_SECTOR!,
                Description: sect.DESCRIPCION,
              };
              sectorAux.push(aux);
              return aux;
            });
            d.SectorxCompany!.SectorxCompañia.map((sect: SectorxCompañia) => {
              let aux: SectorxCompany = {
                IDColdex: coldex.id!,
                IdIndustry: sect.ID_INDUSTRIA,
                CompanyType: sect.TIPO_INDUSTRIA,
                TDOC: sect.TIPO_DOC,
                NDOC: sect.NUMERO_DOC.toString(),
              };
              sectorxCAux.push(aux);
              return aux;
            });
            d.ColdexConfig.ColdexConfig.map((sect: ColdexConfigColdex) => {
              let aux: ColdexConfig = {
                IDColdex: coldex.id,
                IDPoll: `${sect.ID_POLL}`?.trim() ? `${sect.ID_POLL}`.trim() : undefined,
                IDCategory: `${sect.ID_PARTICIPANTE}`?.trim() ? `${sect.ID_PARTICIPANTE}`.trim() : undefined,
                IDType: `${sect.ID_TIPO}`?.trim() ? `${sect.ID_TIPO}`.trim() : undefined,
                IDParent: !isNaN(Number(`${sect.ID_PARENT}`?.trim())) ? Number(`${sect.ID_PARENT}`.trim()) : undefined,
                PollLevel: !isNaN(Number(`${sect.POLL_LEVEL}`?.trim())) ? Number(`${sect.POLL_LEVEL}`.trim()) : undefined,
                PollType: `${sect.POLL_TYPE}`?.trim() ? `${sect.POLL_TYPE}`.trim() : undefined,
                Value: `${sect.VALUE}`?.trim() ? `${sect.VALUE}`.trim() : undefined,
                Calification: !isNaN(Number(`${sect.CALIFICACION}`?.trim())) ? Number(`${sect.CALIFICACION}`.trim()) : undefined,
                ResponseType: `${sect.TIPO_RESPUESTA}`?.trim() ? `${sect.TIPO_RESPUESTA}`.trim() : undefined,
                RelatedQuestion: !isNaN(Number(`${sect.PREGUNTA_RELACIONADA}`?.trim())) ? `${sect.PREGUNTA_RELACIONADA}`.trim() : undefined,
                ConditionValue: `${sect.VALOR_CONDICIONAL}`?.trim() ? `${sect.VALOR_CONDICIONAL}`.trim() : undefined,
              };
              coldexConfigAux.push(aux);
              return aux;
            });
            d.Users!.Usuarios.forEach((element: Usuarios) => {
              let newUser: User = {
                id: null!,
                name: element.NOMBRES,
                lastName: element.APELLIDOS,
                email: element.EMAIL,
                password: "",
                state: true,
                idRol: ID_ROL_USERCOLDEX,
                tdocCompany: element.TDOC.toString(),
                ndocCompany: element.NDOC.toString(),
                phone : element.CELULAR.toString(),
                firstTimeLogging:true
              };
              users.push(newUser);
              usersxColdex.push({
                idColdex: coldex.id!,
                state: false,
                email: element.EMAIL,
                idType: element.TYPE,
                position:element.CARGO
              });
            });
            d.Padrinos!.Padrinos.forEach((element:Padrinos)=>{
              let newUser: User = {
                id: null!,
                name: element.NOMBRES,
                lastName: element.APELLIDOS,
                email: element.EMAIL,
                password: "",
                state: true,
                idRol: ID_ROL_PADRINO,
                tdocCompany: element.TDOC.toString(),
                ndocCompany: element.NDOC.toString(),
                firstTimeLogging:true,
                phone : element.CELULAR.toString(),
              };
              padrinosuser.push(newUser)
              padrinos.push({
                idColdex: coldex.id!,
                email: element.EMAIL,
                state: false,
                idType: element.TYPE,
                tdocCompany: element.TDOC.toString(),
                ndocCompany: element.NDOC.toString(),
              })
            })

            template.profiles = d.Profiles!.Profiles.map((element: Profiles) => ({
              idColdex: coldex.id!,
              acronym: element.ID_PERFIL,
              name: element.NOMBRE_PERFIL,
              description: element.DESCRIPCION_PERFIL
            } as ProfilexColdex));
            template.pollTypes = d.PollTypes!.PollTypes.map((element: PollTypes) => ({
              idColdex: coldex.id!,
              acronym: element.POLL_TYPE_ID,
              name: element.NOMBRE_POLL_TYPE,
            } as PollTypexColdex));
            template.profilePollTypes = d.ProfilePollTypes!.ProfilePollTypes.map((element: ProfilePollTypes) => ({
              idColdex: coldex.id!,
              idProfile: element.ROLE,
              idPollType: element.POLL_TYPE_ID,
              idPoll: element.ID_POLL,
              idParticipator: element.ID_PARTICIPANTE,
              idType: element.ID_TIPO,
              pollLevel: element?.POLL_LEVEL?.toString(),
            } as ProfilePollType));
            template.responseTypes = d.ResponseTypes!.ResponseTypes.map((element: ResponseTypes) => ({
              idColdex: coldex.id!,
              name: `${element.NOMBRE}`,
              type: `${element.TIPO}`,
              minValue: element.VALOR_MIN ? Number(element.VALOR_MIN) : undefined,
              maxValue: element.VALOR_MAX ? Number(element.VALOR_MAX) : undefined,
              precision: element.PRECISION ? Number(element.PRECISION) : undefined,
              minLength: element.LARGO_MIN ? Number(element.LARGO_MIN) : undefined,
              maxLength: element.LARGO_MAX ? Number(element.LARGO_MAX) : undefined,
              symbol: element.SIMBOLO ? `${element.SIMBOLO}` : undefined,
              symbolPosition: element.POSICION_SIMBOLO ? `${element.POSICION_SIMBOLO}` : undefined,
            } as ResponseType));

            const newUsersArray=users.map(user=>{
              const padrinoTemp = padrinosuser.find(padrino=>padrino.email==user.email)
              if(padrinoTemp!==undefined){
                padrinosuser=padrinosuser.filter(padrino=>padrino.email!==padrinoTemp.email)
                  return padrinoTemp
              }else{
                  return user
              }
          })

            setUsersColdex(newUsersArray.concat(padrinosuser));
            setSectors(sectorAux);
            setSectorxCompany(sectorxCAux);
            setColdexConfig(coldexConfigAux);
            setUsersxColdex(usersxColdex);
            setPadrinoxColdex(padrinos);
            setTemplate(template);
          } else if (type ===  TITLE_COLDEX_SECTION_RED) {
            let participantsAux: Array<Sector> = [];
            let coldexConfigAux: Array<ColdexConfig> = [];
            let sectorxCAux: Array<SectorxCompany> = [];
          const template: TemplatexColdex = {
            idColdex: coldex.id!,
            profiles: [],
            pollTypes: [],
            profilePollTypes: [],
            responseTypes: [],
          };
            d.Sectors.Sectores.map((sect: Sectores) => {
              let aux: Sector = {
                IDColdex: coldex.id!,
                code: sect.ID_CATEGORIA!,
                Description: sect.DESCRIPCION,
              };
              participantsAux.push(aux);
              return aux;
            });
            d.SectorxCompany!.SectorxCompañia.map((sect: SectorxCompañia) => {
              let aux: SectorxCompany = {
                IDColdex: coldex.id!,
                IdIndustry: sect.ID_INDUSTRIA,
                CompanyType: sect.TIPO_INDUSTRIA,
                TDOC: sect.TIPO_DOC,
                NDOC: sect.NUMERO_DOC.toString(),
              };
              sectorxCAux.push(aux);
              return aux;
            });

            d.ColdexConfig.ColdexConfig.map((sect: ColdexConfigColdex) => {
              let aux: ColdexConfig = {
                IDColdex: coldex.id,
                IDPoll: `${sect.ID_POLL}`?.trim() ? `${sect.ID_POLL}`.trim() : undefined,
                IDCategory: `${sect.ID_PARTICIPANTE}`?.trim() ? `${sect.ID_PARTICIPANTE}`.trim() : undefined,
                IDType: `${sect.ID_TIPO}`?.trim() ? `${sect.ID_TIPO}`.trim() : undefined,
                IDParent: !isNaN(Number(`${sect.ID_PARENT}`?.trim())) ? Number(`${sect.ID_PARENT}`.trim()) : undefined,
                PollLevel: !isNaN(Number(`${sect.POLL_LEVEL}`?.trim())) ? Number(`${sect.POLL_LEVEL}`.trim()) : undefined,
                PollType: `${sect.POLL_TYPE}`?.trim() ? `${sect.POLL_TYPE}`.trim() : undefined,
                Value: `${sect.VALUE}`?.trim() ? `${sect.VALUE}`.trim() : undefined,
                Calification: !isNaN(Number(`${sect.CALIFICACION}`?.trim())) ? Number(`${sect.CALIFICACION}`.trim()) : undefined,
                ResponseType: `${sect.TIPO_RESPUESTA}`?.trim() ? `${sect.TIPO_RESPUESTA}`.trim() : undefined,
                RelatedQuestion: !isNaN(Number(`${sect.PREGUNTA_RELACIONADA}`?.trim())) ? `${sect.PREGUNTA_RELACIONADA}`.trim() : undefined,
                ConditionValue: `${sect.VALOR_CONDICIONAL}`?.trim() ? `${sect.VALOR_CONDICIONAL}`.trim() : undefined,
              };
              coldexConfigAux.push(aux);
              return aux;
            });
            d.Users!.Usuarios.forEach((element: Usuarios) => {
              let newUser: User = {
                id: null!,
                name: element.NOMBRES,
                lastName: element.APELLIDOS,
                email: element.EMAIL,
                password: "",
                state: true,
                idRol: ID_ROL_USERCOLDEX,
                tdocCompany: element.TDOC.toString(),
                ndocCompany: element.NDOC.toString(),
                phone : element.CELULAR.toString(),
                firstTimeLogging:true
              };
              users.push(newUser);
              usersxColdex.push({
                idColdex: coldex.id!,
                state: false,
                email: element.EMAIL,
                idType: element.TYPE,
                position:element.CARGO
              });
            });
            d.Padrinos!.Padrinos.forEach((element:Padrinos)=>{
              let newUser: User = {
                id: null!,
                name: element.NOMBRES,
                lastName: element.APELLIDOS,
                email: element.EMAIL,
                password: "",
                state: true,
                idRol: ID_ROL_PADRINO,
                tdocCompany: element.TDOC.toString(),
                ndocCompany: element.NDOC.toString(),
                firstTimeLogging:true,
                phone : element.CELULAR.toString(),
              };
              padrinosuser.push(newUser)
              padrinos.push({
                idColdex:  coldex.id!,
                email: element.EMAIL,
                state: false,
                idType: element.TYPE,
                tdocCompany: element.TDOC.toString(),
                ndocCompany: element.NDOC.toString(),
              })
            })
            template.profiles = d.Profiles!.Profiles.map((element: Profiles) => ({
              idColdex: coldex.id!,
              acronym: element.ID_PERFIL,
              name: element.NOMBRE_PERFIL,
              description: element.DESCRIPCION_PERFIL
            } as ProfilexColdex));
            template.pollTypes = d.PollTypes!.PollTypes.map((element: PollTypes) => ({
              idColdex: coldex.id!,
              acronym: element.POLL_TYPE_ID,
              name: element.NOMBRE_POLL_TYPE,
            } as PollTypexColdex));
            template.profilePollTypes = d.ProfilePollTypes!.ProfilePollTypes.map((element: ProfilePollTypes) => ({
              idColdex: coldex.id!,
              idProfile: element.ROLE,
              idPollType: element.POLL_TYPE_ID,
              idPoll: element.ID_POLL,
              idParticipator: element.ID_PARTICIPANTE,
              idType: element.ID_TIPO,
              pollLevel: element?.POLL_LEVEL?.toString(),
            } as ProfilePollType));
            template.responseTypes = d.ResponseTypes!.ResponseTypes.map((element: ResponseTypes) => ({
              idColdex: coldex.id!,
              name: `${element.NOMBRE}`,
              type: `${element.TIPO}`,
              minValue: element.VALOR_MIN ? Number(element.VALOR_MIN) : undefined,
              maxValue: element.VALOR_MAX ? Number(element.VALOR_MAX) : undefined,
              precision: element.PRECISION ? Number(element.PRECISION) : undefined,
              minLength: element.LARGO_MIN ? Number(element.LARGO_MIN) : undefined,
              maxLength: element.LARGO_MAX ? Number(element.LARGO_MAX) : undefined,
              symbol: element.SIMBOLO ? `${element.SIMBOLO}` : undefined,
              symbolPosition: element.POSICION_SIMBOLO ? `${element.POSICION_SIMBOLO}` : undefined,
            } as ResponseType));

            const newUsersArray=users.map(user=>{
              const padrinoTemp = padrinosuser.find(padrino=>padrino.email==user.email)
              if(padrinoTemp!==undefined){
                padrinosuser=padrinosuser.filter(padrino=>padrino.email!==padrinoTemp.email)
                  return padrinoTemp
              }else{
                  return user
              }
          })
            setParticipants(participantsAux);
            setUsersColdex(newUsersArray.concat(padrinosuser));
            setSectorxCompany(sectorxCAux);
            setColdexConfig(coldexConfigAux);
            setUsersxColdex(usersxColdex);
            setPadrinoxColdex(padrinos);
            setTemplate(template);
          }else if(type===TITLE_COLDEX_SECTION_PUBLICO){
            let participantsAux: Array<Sector> = [];
            let coldexConfigAux: Array<ColdexConfig> = [];
            let permissonAux : Array<IPermissionColdex>=[]
            d.Sectors.Sectores.map((sect: Sectores) => {
              let aux: Sector = {
                IDColdex: coldex.id!,
                code: sect.ID_PARTICIPANTE!,
                Description: sect.DESCRIPCION,
              };
              participantsAux.push(aux);
              return aux;
            });
            d.ColdexConfig.ColdexConfig.map((sect: ColdexConfigColdex) => {
              let aux: ColdexConfig = {
                IDColdex: coldex.id,
                IDPoll: sect.ID_POLL,
                IDCategory: sect.ID_PARTICIPANTE,
                IDParent: sect.ID_PARENT,
                PollLevel: sect.POLL_LEVEL,
                PollType: sect.POLL_TYPE,
                Value: sect.VALUE,
                Calification:
                  sect.CALIFICACION !== undefined || null ? sect.CALIFICACION : 0,
                IDType: 'DEFAULT',
              };
              coldexConfigAux.push(aux);
              return aux;
            });
            usersxColdex.push({
              idColdex: coldex.id!,
              state: false,
              email:'useraue',
              idType:'DEFAULT',
              position:'GERENTE'
            });
            permissonAux= coldexConfigAux.filter(conf=>conf.PollType=="Tematica").map((conf)=>{
              return ({
                idColdex:coldex.id!,
                idPoll: conf.IDPoll!,
                idCategory: conf.IDCategory!,
                email:'useraue',
                pollLevel: conf.PollLevel!,
                tdocCompany:'TDOC', 
                ndocCompany:'DEFAULT'
              })
            })
            setParticipants(participantsAux);
            setColdexConfig(coldexConfigAux);
            setPermissions(permissonAux);
            setUsersxColdex(usersxColdex);
          }
        });
      };
      return {
        file,
        sectors,
        sectorxCompany,
        coldexConfig,
        permissions,
        participants,
        usersColdex,
        usersxColdex,
        padrinoxColdex,
        template,
        handleProcessFile
      }
      
}

export default useActionsUploadTemplate;