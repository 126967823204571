import { Action, Dispatch } from "@reduxjs/toolkit";
import { ColdexAPI } from "../../../apis/apiColdex/ColdexAPI";
import {
  MESSAGE_ACTION_TYPE_CREATE,
  MESSAGE_ACTION_TYPE_GET,
  MESSAGE_CONTEXT__CATEGORY,
  MESSAGE_ERROR_TIMEOUT,
} from "../../../utils/constants/message-constants";
import { IApiResult } from "../../../utils/interfaces/store/IApiResponse";
import {
  cleanTemplate,
  setError,
  setTemplatexColdex,
  startLoadingTemplate,
} from "./templatexColdexSlice";
import { TemplatexColdex } from "../../../utils/types/TemplatexColdex";

export const CleanTemplateEffet = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(cleanTemplate())
  };
};

export const CreateTemplate = (template: TemplatexColdex) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(startLoadingTemplate())
    let response;
    try {
      response = await ColdexAPI.post<IApiResult<TemplatexColdex>>(
        `adminColdex/loadTemplate`,
        template
      );
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT__CATEGORY}`,
          iserror: true,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT__CATEGORY}`,
          open: true,
        })
      );
      return;
    }
    if (response.data.dataError) {
      dispatch(
        setError({
          error: `${response.data.resultMessage} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT__CATEGORY}`,
          iserror: true,
          shorterror: `${response.data.shortMessage} en ${MESSAGE_CONTEXT__CATEGORY}`,
          open: true,
        })
      );
      return;
    }

    dispatch(setTemplatexColdex({resultObject: template}))
  };
};

export const resetError = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(
      setError({
        error: "",
        iserror: false,
        shorterror: "",
        open: false,
      })
    );
  };
};
